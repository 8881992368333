import React, { Fragment, useState } from "react";
import bannerMac from "../assets/laptop-image.png";
import FAQCard from "@/components/FAQCard";
import GenericApi from "@/api/genericApi";
import { PricingCard } from "@/components";
import { PricingPlansQueryKey } from "@/config/constants/QueryKeys";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { RoutesPathList } from "@/config/routes/Routes";
import { ApiResponse } from "@/types/Api";
import { PricingPlan } from "@/types/Payment";
import { useQuery } from "@tanstack/react-query";

const Payroll: React.FC = () => {
  const { data: pricingPlans } = useQuery<ApiResponse<PricingPlan[]>>({
    queryFn: async () => GenericApi.get<PricingPlan[]>(ApiRoutes.PRICING),
    queryKey: [PricingPlansQueryKey],
  });

  const [openId, setOpenId] = useState<number | null>(null);

  const handleToggle = (id: number) => {
    setOpenId(openId === id ? null : id);
  };

  return (
    <Fragment>
      <section className="bg-secondary-700">
        <div className="flex w-[80%] px-4 py-8 mx-auto gap-8 lg:grid-cols-12 max-[899px]:flex-col-reverse max-[899px]:items-center">
          <div className="mr-auto place-self-center lg:col-span-7 max-[899px]:text-center">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-primary-700">
            All your Payroll needs at your finger tips (plus, <span className="underline">all</span> your reports are government approved)<span className="element"></span>
            </h1>
            <p className="max-w-2xl mb-6 font-light text-textcolor-700 md:text-lg lg:text-xl">
              
              Payroll can be a huge pain to handle, especially when you have
              dozens of other priorities within your business. That’s why we’ve
              built our Payroll system to be easy to use and fluid.
              <br />
              <br />
              Complete your monthly payroll (and be government compliant)
              without spending hours ‘spinning your wheels’ and getting nowhere.
            </p>
            <div className="flex items-center space-x-5 max-[899px]:justify-center">
              <a
                href="https://calendly.com/pandatax-sales/pandatax-demo"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                Book Your Demo
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
          <img className="w-[60%] h-[60%]" src={bannerMac} alt="mockup" />
        </div>
      </section>

      <section className="bg-blue-700">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-screen-md mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl font-extrabold text-white">
              Built with the strict Payroll regulations in mind.
            </h2>
            <p className="text-white sm:text-xl">
              Payroll is no joke. One wrong move and you’ll have to back-track
              weeks, even months of progress (and have to pay ridiculous fines
              if it gets bad enough). That’s why we made sure that everything is
              up to standards.
            </p>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-white dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 5c0-.6.4-1 1-1h11a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-1a1 1 0 1 1 0-2h1V6H9a1 1 0 0 1-1-1Z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4 7a2 2 0 0 0-2 2v9c0 1.1.9 2 2 2h11a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2H4Zm0 11v-5.5h11V18H4Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-white dark:text-white">
                Government Approved Reports
              </h3>
              <p className="text-white dark:text-gray-400">
                Following the Cypriot Government format; all you have to do is
                fill out the payslip. After that, PandaTax will follow the exact
                guideline and export a report that is ready to submit for your
                business.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-white dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeWidth="2"
                    d="M7 17v1c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-white dark:text-white">
                Integrated with your Accounting
              </h3>
              <p className="text-white dark:text-gray-400">
                We’ve made sure that whatever you do on the Payroll side of
                things, it is automatically logged on your Accounting side. 1+1
                = 2 after all.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-white dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 19H5a1 1 0 0 1-1-1v-1a3 3 0 0 1 3-3h2m10 1a3 3 0 0 1-3 3m3-3a3 3 0 0 0-3-3m3 3h1m-4 3a3 3 0 0 1-3-3m3 3v1m-3-4a3 3 0 0 1 3-3m-3 3h-1m4-3v-1m-2.1 1.9-.7-.7m5.6 5.6-.7-.7m-4.2 0-.7.7m5.6-5.6-.7.7M12 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-white dark:text-white">
                View all Employees at a glance
              </h3>
              <p className="text-white dark:text-gray-400">
                Be able to pull up any employee, any month, or view everyone at
                a glance - you can have a microscopic, or an eagle eye view on
                what’s happening
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-white dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 9a3 3 0 0 1 3-3m-2 15h4m0-3c0-4.1 4-4.9 4-9A6 6 0 1 0 6 9c0 4 4 5 4 9h4Z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-white dark:text-white">
                Easy to navigate Interface
              </h3>
              <p className="text-white dark:text-gray-400">
                We’ve designed our Payroll system to be natural and easy to use,
                allowing you to just ‘do’ what you need to do, versus figuring
                out how to navigate a foreign platform.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center space-x-5 pb-16">
          <a
            href="https://calendly.com/pandatax-sales/pandatax-demo"
            className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-blue-700 bg-white border border-textcolor-700 rounded-lg  focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            Book your Demo here
            <svg
              className="w-5 h-5 ml-2 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </section>

      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-4xl text-center mx-auto">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900">
              Let’s show you the power of PandaTax.
            </h2>
            <p className="mb-6 font-light text-gray-500 sm:text-xl">
              We’re here to help make running your business easier, and be the
              backbone of your Financial Department. Give us a chance to show
              you what we can do, and we promise you won’t be disappointed.
            </p>
            <p className="mb-8 font-light text-gray-500 sm:text-xl">
              Schedule your free Demo Call below, and let’s get started!
            </p>

            <div className="flex flex-col space-y-4 sm:flex-row justify-center sm:space-y-0 sm:space-x-4">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                Book Your Demo
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="w-[65%] mx-auto mb-12 flex flex-col items-center max-[899px]:w-[95%] max-[899px]:text-center">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-blue-700">
          Our Transparent Pricing at a Glance
        </h2>
        <div className="flex justify-around gap-16 max-w-[80%] w-full max-[899px]:max-w-[95%] max-[899px]:flex-col">
          {/*<div className="w-[20rem]">*/}
          {/*<PricingCardSimple*/}
          {/*  title="Solo Proprietor"*/}
          {/*  description="For the Business Owner and his Accountant"*/}
          {/*  price={87}*/}
          {/*  companies={1}*/}
          {/*  users={2}*/}
          {/*  isCurrentPlan={false}*/}
          {/*  onStartNow={() => {*/}
          {/*    window.location.href='/register'*/}
          {/*  }}*/}
          {/*/>*/}
          {/*</div>*/}
          {/*<div className="w-[20rem]">*/}
          {/*<PricingCardSimple*/}
          {/*  title="Accountant"*/}
          {/*  description="For the Accounting firm that needs a reliable and scalable solution for their business."*/}
          {/*  price={119}*/}
          {/*  companies={15}*/}
          {/*  users={5}*/}
          {/*  isCurrentPlan={false}*/}
          {/*  onStartNow={() => {*/}
          {/*    window.location.href='/register'*/}
          {/*  }}*/}
          {/*/>*/}
          {/*</div>*/}
          {pricingPlans?.data?.map((plan) => (
            <PricingCard
              id={plan.id}
              key={plan.id}
              title={plan.name}
              price={plan.price}
              companies={plan.companies}
              users={plan.users}
              isCurrentPlan={false}
              description={plan.description}
              onStartNow={() => {
                window.location.href = RoutesPathList.Register;
              }}
              isLoading={false}
            />
          ))}
        </div>
        <div className="mt-24 text-4xl tracking-tight font-extrabold text-blue-700">
          Refund/Cancellation Policy
        </div>
        <div className="mb-8 font-light lg:text-xl px-24 text-center mt-4 max-[899px]:px-4">
          If you're not satified with our platform for any reason you can cancel
          and receive a full refund within 7 days of your purchase. Please note
          that Crypto Credits are non-refundable.
        </div>
      </section>

      <section className="w-[65%] mx-auto mb-[4rem] max-[899px]:w-[95%]">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-blue-700">
          FAQ’s
        </h2>
        <FAQCard
          id={1}
          title="What is a Web-Faced solution?"
          content="Web-faced simply means that it is hosted on the cloud, and is
            accessible via the internet. This allows you to have lightning fast
            speeds when processing your daily tasks, as well as top of the line
            security for all your private documents and statements."
          isOpen={openId === 1}
          onToggle={handleToggle}
        />
        <FAQCard
          id={2}
          title="Why is Web-Faced better than the traditional download to use
            software?"
          content="Simply put, it is less expensive to set up for you by thousands. We
            skip the complicated process of downloading a software on your
            primary computer, allowing you to begin using the power of PandaTax
            within 5 minutes of setting up your account."
          isOpen={openId === 2}
          onToggle={handleToggle}
        />
        <FAQCard
          id={3}
          title=" Does PandaTax have any setup fees?"
          content="None at all. All you have to do is create your account, pick the
            plan that works for you and you’re off to the races."
          isOpen={openId === 3}
          onToggle={handleToggle}
        />
        <FAQCard
          id={4}
          title="Does PandaTax have any hidden fees?"
          content="None at all. What you see is what you get. We aim to be as
            transparent as possible because we believe all relationships need
            honesty and integrity to flourish. Our ‘Pay-as-you-grow’ model is
            adjusted directly to your business and only increases when you pass
            a threshold. Plus, our Crypto Reports/Exchanges are transparently
            explained within the billing section of your account."
          isOpen={openId === 4}
          onToggle={handleToggle}
        />
        <FAQCard
          id={5}
          title="What do you mean ‘All-in-one?’"
          content="We aim to provide all the features any business would need to run
            their Accounting, Payroll, and invoicing. We constantly push updates
            and add new features to make PandaTax as robust as possible."
          isOpen={openId === 5}
          onToggle={handleToggle}
        />
      </section>
    </Fragment>
  );
};

export default Payroll;
