import React, { Fragment, useState } from 'react'
import GenericApi from '@/api/genericApi'
import { TransactionsQueryKey } from '@/config/constants/QueryKeys' // modified
import { ApiRoutes } from '@/config/routes/ApiRoutes'
import { ApiResponse, ApiError } from '@/types/Api'
import { FormError, ValidationErrors } from '@/types/ValidationError'
import CustomLogger from '@/utils/CustomLogger'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackBarAlert } from '@/hooks/useSnackbar'
import { AxiosError, isAxiosError } from 'axios'
import { AdaptiveModal, LoadingSpinner } from '..'
import { CustomModalPosition } from '@/types/Modal'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import FormField from '../CustomForm/FormField'
import { generateTransactionsUpdateInputs } from '@/utils/generateInputs' // modified
import { Transaction, TransactionType, TransactionUpdate, TransactionUpdateSchema } from '@/types/Banking/Transactions'
import { formatDate } from '@/utils/utils'

export interface TransactionsItemProps {  // modified ***
    transaction: TransactionType 
}


const TransactionsItem: React.FC<TransactionsItemProps> = ({ transaction }) => {  // modified ***

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<TransactionUpdate>({ // modified ***
        resolver: zodResolver(TransactionUpdateSchema), // modified ***
    });

    console.log({
        errors,
        transaction,
    })

    const queryClient = useQueryClient()
    const { showSnackBar } = useSnackBarAlert()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)

    const deleteTransactionMutation = useMutation<ApiResponse<{ message: string }>, ApiError<ValidationErrors>, { id: number }>( // modified ***
        {
            mutationFn: (data) => GenericApi.delete<{ message: string }>(`${ApiRoutes.TRANSACTIONS}/${data.id}`), // modified ***
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                queryClient.invalidateQueries({ queryKey: [TransactionsQueryKey] }) // modified ***
                showSnackBar("Transaction deleted", 'success', { vertical: 'top', horizontal: 'right' }, 3000) // modified ***
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error deleting Transaction", 'error', { vertical: 'top', horizontal: 'right' }, 3000) // modified ***
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            console.log(error)
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            console.log(error)
            return
        }

        console.log(error)
    }

    const handleDelete = (id: number) => {
        deleteTransactionMutation.mutate({ id }) // modified ***
    }

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen)
    }

    const updateTransactionMutation = useMutation<ApiResponse<Transaction>, ApiError<ValidationErrors>, TransactionUpdate>( // modified ***
        {
            mutationFn: (data) => GenericApi.put<Transaction>(`${ApiRoutes.TRANSACTIONS}/${transaction.id}`, data), // modified ***
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                setEditModalOpen(false)
                queryClient.invalidateQueries({ queryKey: [TransactionsQueryKey] }) // modified ***
                showSnackBar("Transaction updated", 'success', { vertical: 'top', horizontal: 'right' }, 3000) // modified ***
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error updating Transaction", 'error', { vertical: 'top', horizontal: 'right' }, 3000) // modified ***
            }
        }
    )

    const inputStyle = (inputName: string) => {
        if(inputName === "reconciled")
          return "w-5 mt-2"
        return "mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
      }


    const updateTransactionInputs = generateTransactionsUpdateInputs(transaction) 

    const onSubmit = (data: TransactionUpdate) => {
        const payload = {
            ...data,
           
        }
        updateTransactionMutation.mutate(payload)
    }

    //TODO MOVE TO UTILS
    const handleDefaultValues = (value: unknown) => {
        switch (typeof value) {
            case 'string':
                return value
            case 'number':
                return Number(value)
            case 'object':
                if (value instanceof Date) {
                    return value.toISOString().split('T')[0]
                }
                return value
            default:
                return value
        }
    }

    return (
        <Fragment>
            <tr
                className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
                
                <td className="px-4 py-3 text-left">{formatDate(transaction.paid_at)}</td> 
                <td className="px-4 py-3 text-left">{transaction.number}</td>
                <td className="px-4 py-3 text-left">{transaction.type}</td>
                <td className="px-4 py-3 text-left">{transaction.category}</td>
                <td className="px-4 py-3 text-left">{transaction.account}</td>
                <td className="px-4 py-3 text-left">{transaction.amount}</td>
                <td className="px-4 py-3 text-right">
                    <button
                        onClick={toggleEditModal}
                        className="font-medium text-green-400 dark:text-green-400 hover:underline"
                    >
                        Edit
                    </button>
                    <span className="mx-2">|</span>
                    <button
                        onClick={toggleDeleteModal}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                    >
                        Delete
                    </button>

                </td>
            </tr>


            <AdaptiveModal
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Delete Transaction</h2>} // modified ***
                position={CustomModalPosition.Center}
                footer={
                    <Fragment>
                        <button
                            onClick={() => handleDelete(transaction.id)} // modified ***
                            className="bg-red-600 text-white px-3 py-1.5 rounded-lg hover:bg-red-700"
                        >
                            {
                                deleteTransactionMutation.isPending ? <LoadingSpinner variant='danger' /> : 'Delete' // modified ***
                            }
                        </button>
                        <button
                            onClick={toggleDeleteModal}
                            className="bg-gray-200 text-gray-800 px-3 py-1.5 rounded-lg hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            >
                <p className="text-gray-700 dark:text-gray-300">
                    Are you sure you want to delete this transaction?
                </p>
                {/* modifica *** */}
            </AdaptiveModal>


            <AdaptiveModal
                isOpen={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Edit Transaction</h2>} // modified ***
                position={CustomModalPosition.Center}
            >
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    {
                        updateTransactionInputs.map(input => (
                            <FormField<TransactionUpdate> // modified ***
                                key={input.name}
                                type={input.type}
                                placeholder={input.placeholder}
                                name={input.name}
                                required={input.required}
                                valueAsNumber={input.valueAsNumber}
                                inputStyle={inputStyle(input.name)}
                                label={input.label}
                                defaultValue={handleDefaultValues(input?.defaultValue)}
                                options={input.options}
                                register={register}
                                error={errors[input.name as keyof TransactionUpdate]} // modified ***
                            />
                        ))
                    }
                    <button type="submit" className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        {
                            updateTransactionMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : "Edit Transaction" // modified ***
                        }

                    </button>
                </form>
            </AdaptiveModal>
        </Fragment>
    )
}

export default TransactionsItem;
