export const OrganizationQueryKey = 'organizations';
export const CompanyQueryKey = 'companies';
export const CountryQueryKey = 'countries';
export const CurrencyQueryKey = 'currencies';
export const PermissionQueryKey = 'permissions';
export const CompanyUsersQueryKey = 'company_users';
export const DoubleEntryAccountQueryKey = 'double_entry_account';
export const DoubleEntryTaxQueryKey = 'double_entry_tax';
export const ItemsQueryKey = 'items';
export const ReconciliationQueryKey = 'reconciliation';
export const TransactionsQueryKey = 'transactions';
export const TransferQueryKey = 'transfer';
export const CategoriesQueryKey = 'categories';
export const ContactsQueryKey = 'contacts';
export const DefaultsQueryKey = 'defaults';
export const DepartmentsQueryKey = 'departments';
export const ExchangeRatesQueryKey = 'exchange_rates';
export const TaxesQueryKey = 'taxes';
export const SalaryQueryKey = 'salary';
export const AccountQueryKey = 'accounts';
export const EmployeeQueryKey = 'employees';
export const TransfersQueryKey = 'transfers';
export const DocumentsQueryKey = 'documents';
export const JournalQueryKey = 'journals';
export const PagesQueryKey = 'pages';
export const JournalEntryQueryKey = 'journal_entries';
export const UserPermissionsQueryKey = 'user_permissions';
export const PermissionUserQueryKey = 'permission_users';
export const PaySlipQueryKey = 'payslips';
export const InvoiceQueryKey = 'invoices';
export const PaymentQueryKey = 'payments';
export const PricingPlansQueryKey = 'pricing_plans';
export const UsersPendingQueryKey = 'users_pending';
export const UserPayQueryKey = 'users-pay';
