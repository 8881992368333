import React, { useEffect, useState } from 'react';
import LoadingPage from '../LoadingPage';
import FallbackPage from '../Fallback';
import GenericApi from '@/api/genericApi';
import { CompanyQueryKey, CurrencyQueryKey, PermissionUserQueryKey, UserPermissionsQueryKey } from '@/config/constants/QueryKeys';
import { ApiRoutes } from '@/config/routes/ApiRoutes';
import { ApiError, ApiResponse } from '@/types/Api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Company, CompanyCreateRequest, CompanyCreateSchema, CompanyLogo } from '@/types/Company';
import { AdaptiveModal, CompanyItem } from '@/components';
import { CustomModalPosition } from '@/types/Modal';
import CustomLogger from '@/utils/CustomLogger';
import FormField from '@/components/CustomForm/FormField';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { ValidationErrors, FormError } from '@/types/ValidationError';
import axios, { AxiosError, isAxiosError } from 'axios';
import { useSnackBarAlert } from '@/hooks/useSnackbar';
import { generateCompanyInputs } from '@/utils/generateInputs';
import { Currency } from '@/types/Currency';
import { getUser, getUserToken } from '@/utils/localStorage';
import { CompanyPermissions, UserPermissions } from '@/types/UserPermissions';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@/store';
import { setPermissions } from '@/store/slices/permissionsSlice';

const CompanyDashboard: React.FC = () => {

    
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        getValues,
    } = useForm<CompanyCreateRequest>({
        resolver: zodResolver(CompanyCreateSchema),
    });


    const queryClient = useQueryClient();
    const { showSnackBar } = useSnackBarAlert();
    const [companyModal, setCompanyModal] = useState(false);
    const selectedCompany = useSelector((state: RootState) => state.company.selectedCompany);
    const [searchValue, setSearchValue] = useState<string | null>(null);
    const [openFilter, setOpenFilter] = useState(false);
    const dispatch = useAppDispatch();
    const user = getUser();
    console.log(user, "user")
    console.log(selectedCompany, "selectedCompany")   


    const { data: currencies, isLoading: isLoadingCurrencies } = useQuery<ApiResponse<Currency[]>>(
        {
            queryFn: async () => GenericApi.get<Currency[]>(ApiRoutes.CURRENCIES),
            queryKey: [CurrencyQueryKey],
        }
    );

    const { data: companies, isLoading, error } = useQuery<ApiResponse<Company[]>>(
        {
            queryFn: async () => GenericApi.get<Company[]>(ApiRoutes.COMPANY),
            queryKey: [CompanyQueryKey],
            enabled: !!currencies?.data,
        }
    );

    const { data:companyPermissions, isLoading:companyPermissionsLoading, error:companyPermissionsError } = useQuery<ApiResponse<CompanyPermissions[]>>({
        queryFn: async () => GenericApi.get(ApiRoutes.INVITE_USER),
        queryKey: [UserPermissionsQueryKey]
    })


 const route = `/users/${user?.id}/permissions`

 console.log(getValues('logo'))

  const {
    data: permissions
  } = useQuery<ApiResponse<UserPermissions[]>>({
    queryKey:[PermissionUserQueryKey, user?.id],
    queryFn: () => GenericApi.get<UserPermissions[]>(route),
  })

    console.log(permissions?.data, "permissions")

  useEffect(() => {
    if (permissions?.data) {
      dispatch(setPermissions(permissions.data))
    }
  }, [permissions?.data,dispatch])

    const companyInputs = generateCompanyInputs(currencies?.data ?? null);
    const addCompanyMutation = useMutation<ApiResponse<Company>, ApiError<ValidationErrors>, CompanyCreateRequest>(
        {
            mutationFn: (data) => GenericApi.post<Company>(ApiRoutes.COMPANY, {
                ...data,
                organization_id: user?.id
            }),
            onSuccess: async ({ data }) => {
                if(data?.id){
                    addCompanyLogo.mutate({
                        id: data.id,
                        // @ts-expect-error select the first file uploaded
                        logo: getValues('logo')?.[0] ?? null
                    })
                }
                CustomLogger.log(data)
                reset()
                setCompanyModal(false)
                queryClient.invalidateQueries({ queryKey: [CompanyQueryKey] })
                showSnackBar("Company created", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error creating company", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )


    const addCompanyLogo = useMutation<
    ApiResponse<CompanyLogo>,
    ApiError<ValidationErrors>,
    { logo: File | null, id: number }
  >({
    mutationFn: async ({ logo, id }) => {
      const formData = new FormData();
      formData.append("logo", logo!);
      const response = await axios.post<ApiResponse<CompanyLogo>>(
        `https://pandatax.ctrl13.ro/api/company/${id}/logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getUserToken()}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      queryClient.invalidateQueries({ queryKey: ["company-logo"] });
      queryClient.invalidateQueries({ queryKey: [CompanyQueryKey] });
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
    },
  });




    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            console.log(error)
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            console.log(error)
            return
        }

        console.log(error)
    }

    const onSubmit = (data: CompanyCreateRequest) => {
        CustomLogger.log(data);
        console.log({
            data
        })
        addCompanyMutation.mutate(data);
    }

    const renderOrganizationBySearchValue = () => {
        if (searchValue) {
            const filteredCompanies = companies?.data?.filter((company) => company.name.toLowerCase().includes(searchValue.toLowerCase()) || company.tax_number.toLowerCase().includes(searchValue.toLowerCase()));
            return filteredCompanies?.map((company) => (
                <CompanyItem currencyOptions={currencies?.data ?? null} company={company} key={company.id} />
            ));
        }

        return companies?.data?.map((company) => (
            <CompanyItem currencyOptions={currencies?.data ?? null} company={company} key={company.id} />
        ));
    }

    const renderUserCompanies = (permissions: CompanyPermissions[]) => {
        if (!user?.is_organization){
            const companies:Company[] = []
            permissions.map((permission) => {
                if(user){
                    if(user.id === permission.user.id){
                        companies.push(permission.company)
                    }
                }

            })
            return companies.map((company) => (
                <CompanyItem currencyOptions={currencies?.data ?? null} company={company} key={company.id} />
            ));          
        }
    }




    const toggleCompanyModal = () => {
        setCompanyModal(!companyModal);
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }

    const onRetry = () => {
        window.location.reload();
    }

    if (isLoading || isLoadingCurrencies || companyPermissionsLoading) return <div className='mt-[4rem]'>
        <LoadingPage />
    </div>;

    const fetchError = error || companies?.error || currencies?.error || companyPermissionsError;

    if (fetchError) {
        console.error(fetchError);
        return <FallbackPage onRetry={onRetry} />;
    }

    if (!currencies?.data || !companies?.data) {
        console.error("Data is undefined");
        return <FallbackPage onRetry={onRetry} />;
    }

    const inputStyle = (inputName: string) => {
        if(inputName === "multicurrency"){
            return "width-[15%] p-2" 
        }
        else if (inputName === "logo"){
            return "w-[50%] mt-1"
        }
       return "mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" 
    }

    return (
        <div className="bg-gray-50 mt-[4rem] dark:bg-gray-900">
            <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-full flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-between md:space-x-3">
                        <div className="flex justify-center items-center gap-4">
                            <form className="flex items-center">
                                <label htmlFor="search" className="sr-only">
                                    Search
                                </label>
                                <div className="relative w-full">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <svg
                                            className="w-4 h-4 text-gray-800 dark:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeWidth="2"
                                                d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                                            />
                                        </svg>
                                    </div>
                                    <input onChange={(e) => setSearchValue(e.target.value)} type="search" id="default-search" className="block w-full ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search" required />
                                </div>
                            </form>
                            <button onClick={toggleFilter} id="filterDropdownButton" data-dropdown-toggle="filterDropdown" className="flex relative items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" className="w-4 h-4 mr-2 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                                </svg>
                                Filter
                                <svg className="-mr-1 ml-1.5 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path clipRule="evenodd" fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                                </svg>
                                {
                                    openFilter ? <div className="z-[9999] absolute top-full w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700">
                                        <h6 className="mb-3 text-sm text-start font-medium text-gray-900 dark:text-white">
                                            Date
                                        </h6>
                                        <ul className="space-y-2 text-sm" aria-labelledby="dropdownDefault">
                                            <li className="flex items-center">
                                                <input id="apple" type="checkbox" value=""
                                                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                <label htmlFor="apple" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                    Ascending
                                                </label>
                                            </li>
                                            <li className="flex items-center">
                                                <input id="fitbit" type="checkbox" value=""
                                                    className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                                <label htmlFor="fitbit" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100">
                                                    Descending
                                                </label>
                                            </li>
                                        </ul>
                                    </div> : null
                                }
                            </button>
                        </div>


                       {user?.is_organization ?  <button
                            onClick={toggleCompanyModal}
                            data-modal-toggle="createCompanyModal"
                            type="button"
                            className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                        >
                            <svg
                                className="h-3.5 w-3.5 mr-2"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                            >
                                <path
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                />
                            </svg>
                            Add Company
                        </button> : null}
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-4 py-3">
                                    Logo
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Tax Id
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Country
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Currency
                                </th>
                                <th scope="col" className="pr-7 py-3 text-right">
                                    Select
                                </th>
                                <th scope="col" className="px-4 py-3 text-right">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {user?.is_organization ? renderOrganizationBySearchValue() : renderUserCompanies(companyPermissions?.data ?? [])}
                        </tbody>
                    </table>
                </div>
            </div>

            <AdaptiveModal
                isOpen={companyModal}
                onClose={() => setCompanyModal(false)}
                position={CustomModalPosition.Center}
                title={<p className='dark:text-white'>Add company</p>}
            >
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    {
                        companyInputs.map((input) => (
                            <FormField<CompanyCreateRequest>
                                key={input.name}
                                type={input.type}
                                placeholder={input.placeholder}
                                name={input.name}
                                required={input.required}
                                valueAsNumber={input.valueAsNumber}
                                inputStyle={inputStyle(input.name)}
                                label={input.label}
                                options={input.options}
                                defaultValue={input.defaultValue}
                                readonly={input?.readonly ?? false}
                                register={register}
                                error={errors[input.name as keyof CompanyCreateRequest]}
                            />
                        ))
                    }
                    <button type="submit" className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        {
                            addCompanyMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : "Create Company"
                        }
                    </button>
                </form>
            </AdaptiveModal>
        </div>
    );
};

export default CompanyDashboard;