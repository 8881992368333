import { z, ZodSchema } from "zod";

export type Account = {
    id: number;
    name: string;
    number: string;
    company_id: number;
    currency: string;
    iban: string;
    swift: string;
    bank_name?: string;
}

export type AccountCreateRequest = {
    name: string;
    number: string;
    // company_id: number;
    currency: string;
    iban: string;
    swift: string;
    bank_name?: string;
}

export type AccountUpdate = {
    name: string;
    number: string;
    currency: string;
    // company_id: number;
    iban: string;
    swift: string;
    bank_name?: string;
}

export const AccountCreateSchema: ZodSchema<AccountCreateRequest> = z.object({
    name: z.string(),
    number: z.string(),
    currency: z.string(),
    // company_id: z.number(),
    swift: z.string(),
    iban: z.string(),
    bank_name: z.string().optional(),
});

export const AccountUpdateSchema: ZodSchema<AccountUpdate> = z.object({
    name: z.string(),
    number: z.string(),
    swift: z.string(),
    iban: z.string(),
    // company_id: z.number(),
    currency: z.string(),
    bank_name: z.string().optional(),
});