import React from "react";
import faq from "../assets/FaQ.svg";

interface AccordionItemProps {
  id: number;
  title: string;
  content: string;
  isOpen: boolean;
  onToggle: (id: number) => void;
}

const FAQCard: React.FC<AccordionItemProps> = ({
  id,
  title,
  content,
  isOpen,
  onToggle,
}) => {
  return (
    <div className="">
      <button
        onClick={() => onToggle(id)}
        className="w-full text-left px-4 py-5  focus:outline-none flex items-center justify-between gap-8"
      >
        <div className="flex items-center justify-start gap-8">
          <h2 className="font-bold lg:text-xl flex gap-2">
            <img className="w-[1.15rem]" src={faq} />
            {title}
          </h2>
        </div>

        {isOpen ? (
          <div className="w-[14px] h-[14px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M0.153898 13.154L6.90421 6.40371L13.6498 13.1493L13.6509 6.85199L6.89949 0.100627L0.157821 6.8423L0.153898 13.154Z"
                fill="#12213B"
                fill-opacity="0.2"
              />
            </svg>
          </div>
        ) : (
          <div className="w-[14px] h-[14px]">
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M13.6449 0.745638L6.89462 7.49595L0.149023 0.750351L0.147975 7.04767L6.89933 13.799L13.641 7.05736L13.6449 0.745638Z"
              fill="#35BCAB"
            />
          </svg>
          </div>
        )}
      </button>
      {isOpen && (
        <div className=" ps-[3rem] py-2 font-light lg:text-base px-6">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default FAQCard;
