import React, { useEffect, useState } from 'react';
import JCC from "@/assets/JCC.png";
import JCCMB from "@/assets/JCCgateway_VisaMastercardAMEX_3DS_V2-NEW2-removebg-preview.png";

const Footer: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  // Funcția pentru detectarea dimensiunilor ecranului
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  console.log(isMobile);

  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <footer className="bg-gray-100">
      <div className="w-full px-4 py-3 flex justify-around items-center max-[899px]:flex-col-reverse max-[899px]:gap-4">
        <span className="text-sm text-black text-center sm:text-center dark:text-gray-400">
          Copyright 2024 <a href="#" className="hover:underline">PandaTax,</a> All Rights Reserved.
        </span>
        <img className='h-[5rem]' src={isMobile ? JCCMB : JCC}/>
      </div>
    </footer>
  );
};

export default Footer;
