import { z, ZodSchema } from "zod";

export type TransferType = {
    id:number;
    company: number; 
    // expense_transaction: string; 
    // income_transaction: string; 
    transferred_at: string; 
    from_account: string ; 
    to_account: string; 
    amount: number; 
    description?: string | null; 
    payment_method: "CASH" | "BANK" | "CREDIT_CARD"; 
    reference?: string | null; 
};

export type TransferCreateRequest = {
    // expense_transaction: string; 
    // income_transaction: string; 
    transferred_at: string; 
    from_account: string; 
    to_account: string; 
    amount: number; 
    description?: string | null; 
    payment_method: "CASH" | "BANK" | "CREDIT_CARD"; 
    reference?: string | null; 
}

export type TransferUpdateRequest = {
    // expense_transaction: string; 
    // income_transaction: string; 
    transferred_at: string; 
    from_account: string; 
    to_account: string;
    amount: number; 
    description?: string | null; 
    payment_method: "CASH" | "BANK" | "CREDIT_CARD"; 
    reference?: string | null; 
}

export const TransferCreateSchema: ZodSchema<TransferCreateRequest> = z.object({
    // expense_transaction: z.string({
    //     message: "Expense transaction is required",
    // }),
    // income_transaction: z.string({
    //     message: "Income transaction is required",
    // }),
    transferred_at: z.string({
        message: "Transferred at is required",
    }),
    from_account: z.string({
        message: "From account is required",
    }),
    to_account: z.string({
        message: "To account is required",
    }),
    amount: z.number({
        message: "Amount is required",
    }),
    description: z.string().optional(),
    payment_method: z.enum(["CASH", "BANK", "CREDIT_CARD"], {
        message: "Payment method is required",
    }),
    reference: z.string().optional(),
});

export const TransferUpdateSchema: ZodSchema<TransferUpdateRequest> = z.object({
    transferred_at: z.string(),
    amount: z.number(),
    from_account: z.string(),
    to_account: z.string(),
    // expense_transaction: z.string(),
    // income_transaction: z.string(),
    description: z.string().nullable(),
    payment_method: z.enum(["CASH", "BANK", "CREDIT_CARD"]),
    reference: z.string().nullable(),
});


type Company = {
    id: number;
    organization: number;
    name: string;
    email: string;
    country: string;
    currency: string;
    phone: string;
    tax_number: string;
    address: string;
    city: string;
    state: string;
    zip_code: string;
};

// type Transaction = {
//     id: number;
//     company: number;
//     type: string;
//     number: string;
//     account: number;
//     paid_at: string;
//     amount: string;
//     currency_code: string;
//     currency_rate: string;
//     document: number;
//     contact: number;
//     description: string;
//     category: string | null;
//     payment_method: string;
//     reference: string;
//     split_id: number;
//     reconciled: boolean;
// };

// type Account = {
//     id: number;
//     type: string;
//     name: string;
//     number: string;
//     currency: string;
//     opening_balance: string;
//     bank_name: string;
//     bank_phone: string;
//     bank_address: string;
// };

export type Transfer = {
    id: number;
    company: Company;
    // expense_transaction: Transaction;
    // income_transaction: Transaction;
    from_account: string;
    to_account: string;
    amount: number;
    description: string;
    transferred_at: string;
    payment_method: "CASH" | "BANK" | "CREDIT CARD";
    reference: string;
};