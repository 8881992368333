import React, { Fragment, useState } from "react";
import bannerMac from "../assets/laptop-image.png";
import GenericApi from "@/api/genericApi";
import { PricingCard } from "@/components";
import FAQCard from "@/components/FAQCard";
import { PricingPlansQueryKey } from "@/config/constants/QueryKeys";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { RoutesPathList } from "@/config/routes/Routes";
import { ApiResponse } from "@/types/Api";
import { PricingPlan } from "@/types/Payment";
import { useQuery } from "@tanstack/react-query";

const Invoicing: React.FC = () => {
  const { data: pricingPlans } = useQuery<ApiResponse<PricingPlan[]>>({
    queryFn: async () => GenericApi.get<PricingPlan[]>(ApiRoutes.PRICING),
    queryKey: [PricingPlansQueryKey],
  });

  const [openId, setOpenId] = useState<number | null>(null);

  const handleToggle = (id: number) => {
    setOpenId(openId === id ? null : id);
  };

  return (
    <Fragment>
      <section className="bg-secondary-700">
        <div className="flex w-[80%] px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12 max-[899px]:flex-col-reverse max-[899px]:items-center">
          <div className="mr-auto place-self-center lg:col-span-7 max-[899px]:text-center">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-primary-700">
              Instant Invoicing Made Easy for your Business.{" "}
              <span className="element"></span>
            </h1>
            <p className="max-w-2xl mb-6 font-light primarylg:mb-8 md:text-lg lg:text-xl text-textcolor-700">
              <br/>
              Headache-free invoicing that is automatically linked to your
              Accounting (eliminate confusion and lost-data from your day-to-day
              business).
            </p>
            <div className="flex flex-row items-center space-x-5 max-[899px]:justify-center">
              <a
                href="https://calendly.com/pandatax-sales/pandatax-demo"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-text-color-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                Book your Demo
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <img className="w-[60%] h-[60%]" src={bannerMac} alt="mockup" />
        </div>
      </section>

      <section className="bg-blue-700">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-10 lg:px-6">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
            All your invoices in one place
          </h2>
          <div className="mt-8 lg:mt-12 space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div>
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z"
                  clipRule="evenodd"
                ></path>
                <path
                  fillRule="evenodd"
                  d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z"
                  clipRule="evenodd"
                ></path>
                <path
                  fillRule="evenodd"
                  d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">
                Recurring Purchases
              </h3>
              <p className="mb-4 text-white">
                Have preferred vendors? Set up an automated standing order and
                generate an invoice with it{" "}
              </p>
            </div>
            <div>
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                <path
                  fillRule="evenodd"
                  d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">
                Everything Connects
              </h3>
              <p className="mb-4  text-white">
                We’ve built invoicing to work seamlessly with your accounting -
                whatever you do, know that it is tracked directly in your
                Accounting
              </p>
            </div>
            <div>
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">
                Custom Templates
              </h3>
              <p className="mb-4  text-white">
                Never waste time again by creating your own Invoice Templates
                that can be used for individual Creditors and Debtors.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-screen-md mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Designed for businesses like yours
            </h2>
            <p className="text-gray-500 sm:text-xl dark:text-gray-400">
              Invoices are simple, yet one of the most essential parts of your
              business. You need them done right, and to automatically be
              registered in your books.
            </p>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-blue-700 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M8 7V6c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1h-1M3 18v-7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                Automatic Tracking
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                All your invoices are tracked within PandaTax, so data can be
                carried over seamlessly to your Accounting.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-primary-600"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"></path>
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                Invoice Grouping
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Group your invoices with specific numbers and codes to make
                sorting through them easier later.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-blue-700 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeWidth="2"
                    d="M9 8h10M9 12h10M9 16h10M5 8h0m0 4h0m0 4h0"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                Detailed Customer and Vendor List
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                All your customers and vendors are a few clicks away within our
                integrated Dashboard.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-blue-700 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 21a9 9 0 1 1 3-17.5m-8 6 4 4L19.3 5M17 14v6m-3-3h6"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                Automatic Calculated VAT{" "}
              </h3>

              <p className="text-gray-500 dark:text-gray-400">
                Whether you’re selling Real Estate, offer Consulting Services,
                or sell items - select your % VAT rate, and our invoices will
                automatically generate the proper rate (and link to your
                accounting).
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full  lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-blue-700 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                Simple, Quick, Easy
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Generate your invoice in a few clicks, with our pre-generated
                template, and email it directly to your clients or vendors.
              </p>
            </div>
            <div>
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
                <svg
                  className="w-12 h-12 text-blue-700 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 7V2.2a2 2 0 0 0-.5.4l-4 3.9a2 2 0 0 0-.3.5H9Zm2 0V2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Zm2-2a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3Zm0 3a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2h-3Zm-6 4c0-.6.4-1 1-1h8c.6 0 1 .4 1 1v6c0 .6-.4 1-1 1H8a1 1 0 0 1-1-1v-6Zm8 1v1h-2v-1h2Zm0 3h-2v1h2v-1Zm-4-3v1H9v-1h2Zm0 3H9v1h2v-1Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
                Automatic Invoice Scheduling
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                Need standing orders for similar orders each month? Set
                automated invoices and watch them be sent to your clients and
                vendors without you lifting a finger!.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center"></div>
      </section>

      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className=" max-w-4xl text-center mx-auto">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Let us show you the power of PandaTax.
            </h2>
            <p className="mb-6 font-light text-gray-500 sm:text-xl dark:text-gray-400">
              We’re here to help make running your business easier, and be the
              backbone of your Financial Department. Give us a chance to show
              you what we can do, and we promise you won’t be disappointed.
            </p>
            <p className="mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">
              Schedule your free Demo Call below, and let’s get started!
            </p>
            <div className="flex flex-col space-y-4 sm:flex-row justify-center sm:space-y-0 sm:space-x-4">
              <a
                href="#"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-text-color-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                Book Your Demo
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 011.414-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="w-[65%] mx-auto mb-12 flex flex-col items-center max-[899px]:w-[95%] max-[899px]:text-center">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-blue-700">
          Our Transparent Pricing at a Glance
        </h2>
        <div className="flex justify-around gap-16 max-w-[80%] w-full max-[899px]:max-w-[95%] max-[899px]:flex-col">
          {/*<div className="w-[20rem]">*/}
          {/*<PricingCardSimple*/}
          {/*  title="Solo Proprietor"*/}
          {/*  description="For the Business Owner and his Accountant"*/}
          {/*  price={87}*/}
          {/*  companies={1}*/}
          {/*  users={2}*/}
          {/*  isCurrentPlan={false}*/}
          {/*  onStartNow={() => {*/}
          {/*    window.location.href='/register'*/}
          {/*  }}*/}
          {/*/>*/}
          {/*</div>*/}
          {/*<div className="w-[20rem]">*/}
          {/*<PricingCardSimple*/}
          {/*  title="Accountant"*/}
          {/*  description="For the Accounting firm that needs a reliable and scalable solution for their business."*/}
          {/*  price={119}*/}
          {/*  companies={15}*/}
          {/*  users={5}*/}
          {/*  isCurrentPlan={false}*/}
          {/*  onStartNow={() => {*/}
          {/*    window.location.href='/register'*/}
          {/*  }}*/}
          {/*/>*/}
          {/*</div>*/}
          {
              pricingPlans?.data?.map((plan) => (
                <PricingCard
                    id={plan.id}
                    key={plan.id}
                    title={plan.name}
                    price={plan.price}
                    companies={plan.companies}
                    users={plan.users}
                    isCurrentPlan={false}
                    description={plan.description}
                    onStartNow={() => {
                        window.location.href = RoutesPathList.Register
                    }}
                    isLoading={false}
                />
            ))
          }
        </div>
        <div className="mt-24 text-4xl tracking-tight font-extrabold text-blue-700">
          Refund/Cancellation Policy
        </div>
        <div className="mb-8 font-light lg:text-xl px-24 text-center mt-4 max-[899px]:px-4">
          If you're not satified with our platform for any reason you can cancel
          and receive a full refund within 7 days of your purchase. Please note
          that Crypto Credits are non-refundable.
        </div>
      </section>

      <section className="w-[65%] mx-auto mb-[4rem] max-[899px]:w-[95%]">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-blue-700">
          FAQ’s
        </h2>
        <FAQCard
          id={1}
          title="What is a Web-Faced solution?"
          content="Web-faced simply means that it is hosted on the cloud, and is
            accessible via the internet. This allows you to have lightning fast
            speeds when processing your daily tasks, as well as top of the line
            security for all your private documents and statements."
          isOpen={openId === 1}
          onToggle={handleToggle}
        />
        <FAQCard
          id={2}
          title="Why is Web-Faced better than the traditional download to use
            software?"
          content="Simply put, it is less expensive to set up for you by thousands. We
            skip the complicated process of downloading a software on your
            primary computer, allowing you to begin using the power of PandaTax
            within 5 minutes of setting up your account."
          isOpen={openId === 2}
          onToggle={handleToggle}
        />
        <FAQCard
          id={3}
          title=" Does PandaTax have any setup fees?"
          content="None at all. All you have to do is create your account, pick the
            plan that works for you and you’re off to the races."
          isOpen={openId === 3}
          onToggle={handleToggle}
        />
        <FAQCard
          id={4}
          title="Does PandaTax have any hidden fees?"
          content="None at all. What you see is what you get. We aim to be as
            transparent as possible because we believe all relationships need
            honesty and integrity to flourish. Our ‘Pay-as-you-grow’ model is
            adjusted directly to your business and only increases when you pass
            a threshold. Plus, our Crypto Reports/Exchanges are transparently
            explained within the billing section of your account."
          isOpen={openId === 4}
          onToggle={handleToggle}
        />
        <FAQCard
          id={5}
          title="What do you mean ‘All-in-one?’"
          content="We aim to provide all the features any business would need to run
            their Accounting, Payroll, and invoicing. We constantly push updates
            and add new features to make PandaTax as robust as possible."
          isOpen={openId === 5}
          onToggle={handleToggle}
        />
      </section>
    </Fragment>
  );
};

export default Invoicing;
