import React, {useEffect} from 'react'
import {useSearchParams} from "react-router-dom";
import GenericApi from "@/api/genericApi.tsx";


const PaymentFailResponse: React.FC = () => {

    const [searchParams] = useSearchParams()

    useEffect(() => {
        const orderId = searchParams.get("orderId");
        GenericApi.get(`/payment/fail/${orderId}`).then((response) => {
            console.log(response)
        }).catch((error) => {
            console.error(error)
        })
    }, [searchParams]);


    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <h1 className="text-2xl font-bold">Payment Response</h1>
                <p className="text-gray-500">Payment response page</p>
                <div className="mt-4">
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                         role="alert">
                        <strong className="font-bold">Failed!{" "}</strong>
                        <span className="block sm:inline">Payment failed</span>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PaymentFailResponse