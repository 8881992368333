import GenericApi from "@/api/genericApi";
import FormField from "@/components/CustomForm/FormField";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { RoutesPathList } from "@/config/routes/Routes";
import { ApiResponse, ApiError } from "@/types/Api";
import {
  AuthResponse,
  RegisterRequest,
  RegisterRequestSchema,
} from "@/types/Auth";
import { FormError, ValidationErrors } from "@/types/ValidationError";
import CustomLogger from "@/utils/CustomLogger";
import { formatError } from "@/utils/formatError";
import { generateRegisterInputs } from "@/utils/generateInputs";
import { wait } from "@/utils/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import React, { useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AdaptiveModal, Logo } from "@/components";
import { CurrencyQueryKey } from "@/config/constants/QueryKeys";
import { Currency } from "@/types/Currency";
import { LoadingPage, FallbackPage } from "..";
import { CustomModalPosition } from "@/types/Modal";

const RegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const [policyModal, setPolicyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [GDPRModal, setGDPRModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<RegisterRequest>({
    resolver: zodResolver(RegisterRequestSchema),
  });

  const {
    data: currencies,
    isLoading: isLoadingCurrencies,
    error,
  } = useQuery<ApiResponse<Currency[]>>({
    queryFn: async () => GenericApi.get<Currency[]>(ApiRoutes.CURRENCIES),
    queryKey: [CurrencyQueryKey],
  });

  const registerMutation = useMutation<
    ApiResponse<AuthResponse>,
    ApiError<ValidationErrors>,
    RegisterRequest
  >({
    mutationFn: (data) =>
      GenericApi.post<AuthResponse>(ApiRoutes.REGISTER, {
        ...data,
      }),
    onSuccess: async () => {
      console.log("registerMutation -> data", "OK");
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      handleAxiosError(error);
    },
  });

  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    if (isAxiosError<FormError>(error)) {
      setError("root", {
        type: "manual",
        message:
          error.response?.data?.detail ?? "An error occurred while registering",
      });
      return;
    }

    if (isAxiosError<ValidationErrors>(error)) {
      setError("root", {
        type: "manual",
        message: formatError(error, "An error occurred while registering"),
      });
      return;
    }

    //* Set error message from server
    setError("root", {
      type: "manual",
      message: error?.message ?? "An error occurred while registering",
    });
  };

  const getFieldError = (error: any): FieldError | undefined => {
    if (error && "type" in error) {
      return error as FieldError;
    } else if (Array.isArray(error)) {
      return error.find((err) => err !== undefined);
    }
    return undefined;
  };

  const onSubmit = async (data: RegisterRequest) => {
    CustomLogger.log(data);
    await registerMutation.mutateAsync(data);
    await wait(10000);
    navigate(RoutesPathList.Login, { replace: true });
  };

  const registerInputs = generateRegisterInputs(currencies?.data ?? null);

  console.log(registerInputs);

  const onRetry = () => {
    window.location.reload();
  };

  if (isLoadingCurrencies)
    return (
      <div>
        <LoadingPage />
      </div>
    );

  const fetchError = error || currencies?.error;

  if (fetchError) {
    console.error(fetchError);
    return <FallbackPage onRetry={onRetry} />;
  }

  if (!currencies?.data) {
    console.error("Data is undefined");
    return <FallbackPage onRetry={onRetry} />;
  }

  const inputStyle = (inputName: string) => {
    if (inputName === "multi_currency") {
      return "mt-2 w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800 transition duration-150 ease-in-out";
    }
    return "bg-gray-50 mt-1 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-900 min-h-[100dvh]">
      <div className="max-w-screen-xl px-4 py-8 mx-auto">
        <div
          onClick={() => navigate(RoutesPathList.Landing)}
          className="cursor-pointer mb-6 text-center"
        >
          <Logo
            size={{
              width: "150px",
              height: "70px",
            }}
          />
        </div>
        <div className="w-full mx-auto bg-white rounded-lg shadow dark:bg-gray-800 md:mt-0 sm:max-w-lg xl:p-0 lg:col-span-7 xl:col-span-6">
          <div className="p-6 space-y-4 lg:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 sm:text-2xl dark:text-white">
              Create your Account
            </h1>
            <p className="text-sm font-light text-gray-500 dark:text-gray-300">
              Create your account in seconds. Already have an account?{" "}
              <a
                href="/login"
                className="font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                Login here
              </a>
              .
            </p>
            {registerMutation.isError ? (
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">{errors.root?.message}</span>
              </div>
            ) : null}
            {registerMutation.isSuccess ? (
              <div
                className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">
                  Check your email to verify your account
                </span>
              </div>
            ) : null}
            <form
              className="space-y-4 lg:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              {registerInputs.map((input, index) => (
                <div key={input.name}>
                  <FormField<RegisterRequest>
                    key={index}
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.name}
                    required={input.required}
                    valueAsNumber={input.valueAsNumber}
                    label={input.label}
                    error={getFieldError(
                      errors[input.name as keyof RegisterRequest]
                    )}
                    register={register}
                    options={input?.options ?? []}
                    inputStyle={inputStyle(input.name)}
                  />
                </div>
              ))}
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      required
                      className='"w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800 transition duration-150 ease-in-out"'
                      type="checkbox"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="terms-of-use"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      I accept the{" "}
                      <a
                        className="text-blue-600 cursor-pointer underline"
                        onClick={() => setTermsModal(true)}
                      >
                        Terms of Service
                      </a>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      required
                      className='"w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800 transition duration-150 ease-in-out"'
                      type="checkbox"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="terms-of-use"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      I accept the{" "}
                      <a
                        className="text-blue-600 cursor-pointer underline"
                        onClick={() => setPolicyModal(true)}
                      >
                        Privacy Policy
                      </a>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      required
                      className='"w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800 transition duration-150 ease-in-out"'
                      type="checkbox"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="terms-of-use"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      I accept the{" "}
                      <a
                        className="text-blue-600 cursor-pointer underline"
                        onClick={() => setGDPRModal(true)}
                      >
                        GDPR Compliance
                      </a>
                    </label>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="w-full flex justify-center items-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                {registerMutation.isPending ? (
                  <svg
                    className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin"
                    viewBox="0 0 24 24"
                  ></svg>
                ) : (
                  "Create Account"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      <AdaptiveModal
        isOpen={policyModal}
        onClose={() => setPolicyModal(false)}
        title={
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Privacy Policy
          </h2>
        }
        position={CustomModalPosition.Center}
        footer={
          <button
            onClick={() => setPolicyModal(false)}
            className="bg-gray-200 text-blue-600 px-3 py-1.5 rounded-lg hover:bg-gray-300"
          >
            Ok
          </button>
        }
      >
        <div>
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-4xl mx-auto my-10">
            <h1 className="text-4xl font-bold text-center mb-6 underline">
              Privacy Policy
            </h1>

            <p className="leading-relaxed mb-2">
              Your privacy is important to us. It is PGC Software-Proz Ltd's
              policy to respect your privacy and comply with any applicable law
              and regulation regarding any personal information we may collect
              about you, including across our website,{" "}
              <a
                href="https://www.pandatax.com"
                className="text-blue-500 underline"
              >
                https://www.pandatax.com
              </a>
              , and other sites we own and operate.
            </p>

            <p className="mb-2">
              Personal information is any information about you which can be
              used to identify you. This includes information about you as a
              person (such as name, address, and date of birth), your devices,
              payment details, and even information about how you use a website
              or online service.
            </p>
            <p className="mb-3">
              In the event our site contains links to third-party sites and
              services, please be aware that those sites and services have their
              own privacy policies. After following a link to any third-party
              content, you should read their posted privacy policy information
              about how they collect and use personal information. This Privacy
              Policy does not apply to any of your activities after you leave
              our site.
            </p>
            <p className="mb-3">
              This policy is effective as of October 21st, 2024
            </p>
            <p>Last updated: October 21st, 2024</p>

            {/* <h2 className="text-2xl font-semibold mt-6 mb-4">
              Information We Collect
            </h2>
            <p className="mb-4">
              Information we collect falls into one of two categories:
              "voluntarily provided" information and "automatically collected"
              information. "Voluntarily provided" information refers to any
              information you knowingly and actively provide us when using or
              participating in any of our services and promotions.
              "Automatically collected" information refers to any information
              automatically sent by your devices in the course of accessing our
              products and services.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Log Data</h2>
            <p className="mb-4">
              When you visit our website, our servers may automatically log the
              standard data provided by your web browser. This may include your
              device’s IP address, your browser type and version, the pages you
              visit, the time and date of your visit, and other details.
            </p>

            <p className="mb-4">
                Additionally, if you encounter certain errors while using the site, we may automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is.
            </p>

            <p className="mb-4">
                Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Device Data</h2>
            <p className="mb-4">
              When you visit our website or interact with our services, we may
              automatically collect data about your device, such as device type
              and operating system. We recommend checking the policies of your
              device manufacturer or software provider for information about
              what they make available to us.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Personal Information
            </h2>
            <p className="mb-4">
              We may ask for personal information, for example, when you
              subscribe to our newsletter or contact us. This may include your
              name, email, phone/mobile number, and mailing address.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              User-Generated Content
            </h2>
            <p className="mb-4">
            We consider “user-generated content” to be materials (text, image and/or video content) voluntarily supplied to us by our users for the purpose of publication, processing, or usage on our platform. All user-generated content is associated with the account or email address used to submit the materials.
            </p>

            <p className="mb-4">
            Please be aware that any content you submit for the purpose of publication will be public after posting (and subsequent review or vetting process). Once published, it may be accessible to third parties not covered under this privacy policy.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Transaction Data
            </h2>
            <p className="mb-4">
            Transaction data refers to data that accumulates over the normal course of operation on our platform. This may include transaction records, stored files, user profiles, analytics data and other metrics, as well as other types of information, created or generated, as users interact with our services.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Security of Your Personal Information
            </h2>
            <p className="mb-4">
              We take commercially acceptable steps to prevent unauthorized
              access, disclosure, or modification of your personal information.
              However, no method of transmission or storage is 100% secure.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              How Long We Keep Your Personal Information
            </h2>
            <p className="mb-4">
              We retain personal information only as long as necessary. If it is
              no longer required, we delete it or make it anonymous.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Children's Privacy
            </h2>
            <p className="mb-4">
              Our products or services are not aimed at children under 13, and
              we do not knowingly collect information from children under 13.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Disclosure of Personal Information to Third Parties
            </h2>
            <p className="mb-4">
              We may disclose personal information to affiliates, service
              providers, employees, legal entities, or regulatory bodies when
              necessary.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Your Rights and Controlling Your Personal Information
            </h2>
            <p className="mb-4">
              You may request access to, correction of, and deletion of your
              personal information. You also have the right to unsubscribe from
              marketing communications.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Use of Cookies</h2>
            <p className="mb-4">
              We use "cookies" to collect information about your activities
              across our site. Refer to our Cookie Policy for more details.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Business Transfers
            </h2>
            <p className="mb-4">
              If we or our assets are acquired, user data, including personal
              information, may be transferred to the acquiring parties.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Limits of Our Policy
            </h2>
            <p className="mb-4">
              We are not responsible for the privacy practices of external sites
              linked on our website.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Changes to This Policy
            </h2>
            <p className="mb-4">
              We may change this policy to reflect updates in our practices or
              applicable laws. Significant changes will be communicated via our
              website or directly to our users.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Additional Disclosures for GDPR Compliance (EU)
            </h2>
            <p className="mb-4">
              We are a Data Controller/Processor under GDPR. We collect and use
              personal information lawfully and transparently, and users have
              rights to restrict processing, request data portability, and
              deletion.
            </p>

            <p className="text-lg mt-6">
              For any questions or concerns regarding your privacy, contact us
              at{" "}
              <a
                href="mailto:pandataxcy@gmail.com"
                className="text-blue-500 underline"
              >
                pandataxcy@gmail.com
              </a>
              .
            </p> */}

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Information We Collect
            </h2>
            <p className="mb-4">
              Information we collect falls into one of two categories:
              "voluntarily provided" information and "automatically collected"
              information.
            </p>

            <p className="mb-4">
              "Voluntarily provided" information refers to any information you
              knowingly and actively provide us when using or participating in
              any of our services and promotions.
            </p>

            <p className="mb-4">
              "Automatically collected" information refers to any information
              automatically sent by your devices in the course of accessing our
              products and services.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Log Data</h2>
            <p className="mb-4">
              When you visit our website, our servers may automatically log the
              standard data provided by your web browser. It may include your
              device’s Internet Protocol (IP) address, your browser type and
              version, the pages you visit, the time and date of your visit, the
              time spent on each page, and other details about your visit.
            </p>

            <p className="mb-4">
              Additionally, if you encounter certain errors while using the
              site, we may automatically collect data about the error and the
              circumstances surrounding its occurrence. This data may include
              technical details about your device, what you were trying to do
              when the error happened, and other technical information relating
              to the problem. You may or may not receive notice of such errors,
              even in the moment they occur, that they have occurred, or what
              the nature of the error is.
            </p>

            <p className="mb-4">
              Please be aware that while this information may not be personally
              identifying by itself, it may be possible to combine it with other
              data to personally identify individual persons.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Device Data</h2>
            <p className="mb-4">
              When you visit our website or interact with our services, we may
              automatically collect data about your device, such as:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Device type</li>
              <li>Operating system</li>
            </ul>

            <p className="mb-4">
              Data we collect can depend on the individual settings of your
              device and software. We recommend checking the policies of your
              device manufacturer or software provider to learn what information
              they make available to us.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Personal Information
            </h2>
            <p className="mb-4">
              We may ask for personal information—for example, when you
              subscribe to our newsletter or when you contact us—which may
              include one or more of the following:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Name</li>
              <li>Email</li>
              <li>Phone/mobile number</li>
              <li>Home/mailing address</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              User-Generated Content
            </h2>
            <p className="mb-4">
              We consider "user-generated content" to be materials (text, image
              and/or video content) voluntarily supplied to us by our users for
              the purpose of publication, processing, or usage on our platform.
              All user-generated content is associated with the account or email
              address used to submit the materials.
            </p>

            <p className="mb-4">
              Please be aware that any content you submit for the purpose of
              publication will be public after posting (and subsequent review or
              vetting process). Once published, it may be accessible to third
              parties not covered under this privacy policy.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Transaction Data
            </h2>
            <p className="mb-4">
              Transaction data refers to data that accumulates over the normal
              course of operation on our platform. This may include transaction
              records, stored files, user profiles, analytics data and other
              metrics, as well as other types of information, created or
              generated, as users interact with our services.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Legitimate Reasons for Processing Your Personal Information
            </h2>
            <p className="mb-4">
              We only collect and use your personal information when we have a
              legitimate reason for doing so. In which instance, we only collect
              personal information that is reasonably necessary to provide our
              services to you.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Collection and Use of Information
            </h2>
            <p className="mb-4">
              We may collect personal information from you when you do any of
              the following on our website:
            </p>
            <ul className="list-disc list-inside mb-4">
              <li>Register for an account</li>
              <li>Purchase a subscription</li>
              <li>
                Sign up to receive updates from us via email or social media
                channels
              </li>
              <li>Use a mobile device or web browser to access our content</li>
              <li>
                Contact us via email, social media, or on any similar
                technologies
              </li>
              <li>When you mention us on social media</li>
            </ul>

            <p className="mb-4">
              We may collect, hold, use, and disclose information for the
              following purposes, and personal information will not be further
              processed in a manner that is incompatible with these purposes:
            </p>

            <ul className="list-disc list-inside mb-4">
              <li>
                to provide you with our platform's core features and services
              </li>
              <li>
                for analytics, market research, and business development,
                including to operate and improve our website, associated
                applications, and associated social media platforms
              </li>
              <li>
                for advertising and marketing, including to send you promotional
                information about our products and services and information
                about third parties that we consider may be of interest to you
              </li>
              <li>
                to enable you to access and use our website, associated
                applications, and associated social media platforms
              </li>
            </ul>

            <p className="mb-4">
              We may combine voluntarily provided and automatically collected
              personal information with general information or research data we
              receive from other trusted sources. For example, If you provide us
              with your location, we may combine this with general information
              about currency and language to provide you with an enhanced
              experience of our site and service.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Security of Your Personal Information
            </h2>
            <p className="mb-4">
              When we collect and process personal information, and while we
              retain this information, we will protect it within commercially
              acceptable means to prevent loss and theft, as well as
              unauthorised access, disclosure, copying, use or modification.
            </p>

            <p className="mb-4">
              Although we will do our best to protect the personal information
              you provide to us, we advise that no method of electronic
              transmission or storage is 100% secure and no one can guarantee
              absolute data security.
            </p>

            <p className="mb-4">
              You are responsible for selecting any password and its overall
              security strength, ensuring the security of your own information
              within the bounds of our services. For example, ensuring any
              passwords associated with accessing your personal information and
              accounts are secure and confidential.{" "}
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              How Long We Keep Your Personal Information
            </h2>
            <p className="mb-4">
              We keep your personal information only for as long as we need to.
              This time period may depend on what we are using your information
              for, in accordance with this privacy policy. For example, if you
              have provided us with personal information as part of creating an
              account with us, we may retain this information for the duration
              your account exists on our system. If your personal information is
              no longer required for this purpose, we will delete it or make it
              anonymous by removing all details that identify you.
            </p>
            <p className="mb-4">
              However, if necessary, we may retain your personal information for
              our compliance with a legal, accounting, or reporting obligation
              or for archiving purposes in the public interest, scientific, or
              historical research purposes or statistical purposes.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Children's Privacy
            </h2>
            <p className="mb-4">
              We do not aim any of our products or services directly at children
              under the age of 13 and we do not knowingly collect personal
              information about children under 13.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Disclosure of Personal Information to Third Parties
            </h2>
            <p> We may disclose personal information to:</p>
            <ul className="list-disc pl-6">
              <li>a parent, subsidiary, or affiliate of our company</li>
              <li>
                third-party service providers for the purpose of enabling them
                to provide their services, including (without limitation) IT
                service providers, data storage, hosting and server providers,
                ad networks, analytics, error loggers, debt collectors,
                maintenance or problem-solving providers, professional advisors,
                and payment systems operators
              </li>
              <li>our employees, contractors, and/or related entities</li>
              <li>our existing or potential agents or business partners</li>
              <li>
                credit reporting agencies, courts, tribunals, and regulatory
                authorities, in the event you fail to pay for goods or services
                we have provided to you
              </li>
              <li>
                courts, tribunals, regulatory authorities, and law enforcement
                officers, as required by law, in connection with any actual or
                prospective legal proceedings, or in order to establish,
                exercise, or defend our legal rights
              </li>
              <li>
                third parties, including agents or sub-contractors who assist us
                in providing information, products, services, or direct
                marketing to you
              </li>
              <li>third parties to collect and process data</li>
              <li>
                an entity that buys, or to which we transfer all or
                substantially all of our assets and business
              </li>
            </ul>
            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Third parties we currently use include:
            </h2>
            <ul className="list-disc pl-6">
              <li>Google Analytics</li>
              <li>Hotjar</li>
              <li>Microsoft analytics</li>
              <li>Square</li>
              <li>International Transfers of Personal Information</li>
            </ul>
            <p className="mb-4">
              The personal information we collect is stored and/or processed in
              Cyprus, Germany, and Romania, or where we or our partners,
              affiliates, and third-party providers maintain facilities.
            </p>
            <p className="mb-4">
              The countries to which we store, process, or transfer your
              personal information may not have the same data protection laws as
              the country in which you initially provided the information. If we
              transfer your personal information to third parties in other
              countries:
              <ul className="list-disc pl-6">
                <li>
                  we will perform those transfers in accordance with the
                  requirements of applicable law
                </li>
                <li>
                  we will protect the transferred personal information in
                  accordance with this privacy policy.
                </li>
              </ul>
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Your Rights and Controlling Your Personal Information
            </h2>
            <p className="font-semibold mt-6 mb-4">Your choice:</p>
            <p>
              By providing personal information to us, you understand we will
              collect, hold, use, and disclose your personal information in
              accordance with this privacy policy. You do not have to provide
              personal information to us, however, if you do not, it may affect
              your use of our website or the products and/or services offered on
              or through it.
            </p>

            <p className=" font-semibold mt-6 mb-4">
              Information from third parties:
            </p>
            <p>
              If we receive personal information about you from a third party,
              we will protect it as set out in this privacy policy. If you are a
              third party providing personal information about somebody else,
              you represent and warrant that you have such person’s consent to
              provide the personal information to us.
            </p>

            <p className=" font-semibold mt-6 mb-4">Marketing permission:</p>
            <p>
              If you have previously agreed to us using your personal
              information for direct marketing purposes, you may change your
              mind at any time by contacting us using the details below.
            </p>

            <p className=" font-semibold mt-6 mb-4">Access:</p>
            <p>
              You may request details of the personal information that we hold
              about you.
            </p>

            <p className=" font-semibold mt-6 mb-4">Correction:</p>
            <p>
              If you believe that any information we hold about you is
              inaccurate, out of date, incomplete, irrelevant, or misleading,
              please contact us using the details provided in this privacy
              policy. We will take reasonable steps to correct any information
              found to be inaccurate, incomplete, misleading, or out of date.
            </p>

            <p className=" font-semibold mt-6 mb-4">Non-discrimination:</p>
            <p>
              We will not discriminate against you for exercising any of your
              rights over your personal information. Unless your personal
              information is required to provide you with a particular service
              or offer (for example processing transaction data), we will not
              deny you goods or services and/or charge you different prices or
              rates for goods or services, including through granting discounts
              or other benefits, or imposing penalties, or provide you with a
              different level or quality of goods or services.
            </p>

            <p className=" font-semibold mt-6 mb-4">
              Downloading of Personal Information:
            </p>
            <p>
              We provide a means for you to download the personal information
              you have shared through our site. Please contact us for more
              information.
            </p>

            <p className=" font-semibold mt-6 mb-4">
              Notification of data breaches:
            </p>
            <p>
              We will comply with laws applicable to us in respect of any data
              breach.
            </p>

            <p className=" font-semibold mt-6 mb-4">Complaints:</p>
            <p>
              If you believe that we have breached a relevant data protection
              law and wish to make a complaint, please contact us using the
              details below and provide us with full details of the alleged
              breach. We will promptly investigate your complaint and respond to
              you, in writing, setting out the outcome of our investigation and
              the steps we will take to deal with your complaint. You also have
              the right to contact a regulatory body or data protection
              authority in relation to your complaint.
            </p>

            <p className=" font-semibold mt-6 mb-4">Unsubscribe:</p>
            <p>
              To unsubscribe from our email database or opt-out of
              communications (including marketing communications), please
              contact us using the details provided in this privacy policy, or
              opt-out using the opt-out facilities provided in the
              communication. We may need to request specific information from
              you to help us confirm your identity.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Use of Cookies</h2>
            <p className="mb-4">
              We use "cookies" to collect information about you and your
              activity across our site. A cookie is a small piece of data that
              our website stores on your computer, and accesses each time you
              visit, so we can understand how you use our site. This helps us
              serve you content based on preferences you have specified.Please
              refer to our Cookie Policy for more information.
            </p>

            <h2 className="text-2xl  font-semibold mt-6 mb-4">
              Business Transfers
            </h2>
            <p className="mb-4">
              If we or our assets are acquired, or in the unlikely event that we
              go out of business or enter bankruptcy, we would include data,
              including your personal information, among the assets transferred
              to any parties who acquire us. You acknowledge that such transfers
              may occur, and that any parties who acquire us may, to the extent
              permitted by applicable law, continue to use your personal
              information according to this policy, which they will be required
              to assume as it is the basis for any ownership or use rights we
              have over such information.
            </p>

            <h2 className="text-2xl  font-semibold mt-6 mb-4">
              Limits of Our Policy
            </h2>
            <p className="mb-4">
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              policies of those sites, and cannot accept responsibility or
              liability for their respective privacy practices.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Changes to This Policy
            </h2>
            <p className="mb-4">
              At our discretion, we may change our privacy policy to reflect
              updates to our business processes, current acceptable practices,
              or legislative or regulatory changes. If we decide to change this
              privacy policy, we will post the changes here at the same link by
              which you are accessing this privacy policy. If the changes are
              significant, or if required by applicable law, we will contact you
              (based on your selected preferences for communications from us)
              and all our registered users with the new details and links to the
              updated or changed policy. If required by law, we will get your
              permission or give you the opportunity to opt in to or opt out of,
              as applicable, any new uses of your personal information.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Additional Disclosures for GDPR Compliance (EU)
            </h2>
            <p className="mb-4">
              We are a Data Controller/Processor under GDPR. We collect and use
              personal information lawfully and transparently, and users have
              rights to restrict processing, request data portability, and
              deletion.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Additional Disclosures for General Data Protection Regulation
              (GDPR) Compliance (EU)
            </h2>

            <p className=" font-semibold mt-6 mb-4">
              Data Controller / Data Processor:
            </p>

            <p className="mb-4">
              The GDPR distinguishes between organisations that process personal
              information for their own purposes (known as "data controllers")
              and organisations that process personal information on behalf of
              other organisations (known as "data processors"). We, PGC
              Software-Proz Ltd at the address provided in our Contact Us
              section, are a Data Controller and/or Processor with respect to
              the personal information you provide to us.
            </p>
            <p className=" font-semibold mt-6 mb-4">
              Legal Bases for Processing Your Personal Information
            </p>
            <p>
              We will only collect and use your personal information when we
              have a legal right to do so. In which case, we will collect and
              use your personal information lawfully, fairly, and in a
              transparent manner. If we seek your consent to process your
              personal information, and you are under 16 years of age, we will
              seek your parent or legal guardian’s consent to process your
              personal information for that specific purpose. Our lawful bases
              depend on the services you use and how you use them. This means we
              only collect and use your information on the following grounds
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Consent From You
            </h2>

            <p className="mb-4">
              Where you give us consent to collect and use your personal
              information for a specific purpose. You may withdraw your consent
              at any time using the facilities we provide; however this will not
              affect any use of your information that has already taken place.
              You may consent to providing your email address for the purpose of
              receiving marketing emails from us. While you may unsubscribe at
              any time, we cannot recall any email we have already sent. If you
              have any further enquiries about how to withdraw your consent,
              please feel free to enquire using the details provided in the
              Contact Us section of this privacy policy.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Performance of a Contract or Transaction
            </h2>

            <p className="mb-4">
              Where you have entered into a contract or transaction with us, or
              in order to take preparatory steps prior to our entering into a
              contract or transaction with you. For example, if you purchase a
              product, service, or subscription from us, we may need to use your
              personal and payment information in order to process and deliver
              your order.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Our Legitimate Interests
            </h2>
            <p>
              Where we assess it is necessary for our legitimate interests, such
              as for us to provide, operate, improve and communicate our
              services. We consider our legitimate interests to include research
              and development, understanding our audience, marketing and
              promoting our services, measures taken to operate our services
              efficiently, marketing analysis, and measures taken to protect our
              legal rights and interests.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Compliance with Law
            </h2>

            <p className="mb-4">
              In some cases, we may have a legal obligation to use or keep your
              personal information. Such cases may include (but are not limited
              to) court orders, criminal investigations, government requests,
              and regulatory obligations. If you have any further enquiries
              about how we retain personal information in order to comply with
              the law, please feel free to enquire using the details provided in
              the Contact Us section of this privacy policy.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              International Transfers Outside of the European Economic Area
              (EEA)
            </h2>
            <p className="mb-4">
              We will ensure that any transfer of personal information from
              countries in the European Economic Area (EEA) to countries outside
              the EEA will be protected by appropriate safeguards, for example
              by using standard data protection clauses approved by the European
              Commission, or the use of binding corporate rules or other legally
              accepted means.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">
              Your Rights and Controlling Your Personal Information
            </h2>
            <p className="mb-4">
              Restrict: You have the right to request that we restrict the
              processing of your personal information if (i) you are concerned
              about the accuracy of your personal information; (ii) you believe
              your personal information has been unlawfully processed; (iii) you
              need us to maintain the personal information solely for the
              purpose of a legal claim; or (iv) we are in the process of
              considering your objection in relation to processing on the basis
              of legitimate interests. Objecting to processing: You have the
              right to object to processing of your personal information that is
              based on our legitimate interests or public interest. If this is
              done, we must provide compelling legitimate grounds for the
              processing which overrides your interests, rights, and freedoms,
              in order to proceed with the processing of your personal
              information. Data portability: You may have the right to request a
              copy of the personal information we hold about you. Where
              possible, we will provide this information in CSV format or other
              easily readable machine format. You may also have the right to
              request that we transfer this personal information to a third
              party. Deletion: You may have a right to request that we delete
              the personal information we hold about you at any time, and we
              will take reasonable steps to delete your personal information
              from our current records. If you ask us to delete your personal
              information, we will let you know how the deletion affects your
              use of our website or products and services. There may be
              exceptions to this right for specific legal reasons which, if
              applicable, we will set out for you in response to your request.
              If you terminate or delete your account, we will delete your
              personal information within 60 days of the deletion of your
              account. Please be aware that search engines and similar third
              parties may still retain copies of your personal information that
              has been made public at least once, like certain profile
              information and public comments, even after you have deleted the
              information from our services or deactivated your account.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Contact Us</h2>

            <p className="mb-2">
              For any questions or concerns regarding your privacy, you may
              contact us using the following details:
            </p>

            <p className="mb-2">
              Panos Constantinou <br />
              pandataxcy@gmail.com
            </p>
            <p className="mb-2">
              We have appointed an internal Data Protection Officer for you to
              contact if you have any questions or concerns regarding your
              privacy, or our policies or practices. The Data Protection
              Officer’s contact details are as follows:
            </p>
            <p className="mb-2">
              Panos Constantinou <br />
              pandataxcy@gmail.com
            </p>
          </div>
        </div>
      </AdaptiveModal>
      <AdaptiveModal
        isOpen={termsModal}
        onClose={() => setTermsModal(false)}
        title={<p>Terms of Service</p>}
        position={CustomModalPosition.Center}
      >
        <div className="terms-of-service">
          <h2 className="text-2xl font-semibold mt-6 mb-4">Terms of Service</h2>
          <p className="leading-relaxed mb-2">
            By accessing{" "}
            <a href="https://www.pandatax.com" target="_blank">
              https://www.pandatax.com
            </a>{" "}
            you agree to abide by these Terms of Service and to comply with all
            applicable laws and regulations. If you do not agree with these
            Terms of Service, you are prohibited from using or accessing this
            website or using any other services provided by PGC Software-Proz
            Ltd.
          </p>

          <p className="leading-relaxed mb-2">
            We, PGC Software-Proz Ltd the right to review and amend any of these
            Terms of Service at our sole discretion. Upon doing so, we will
            update this page. Any changes to these Terms of Service will take
            effect immediately from the date of publication.
          </p>

          <p className="leading-relaxed mb-2">
            These Terms of Service were last updated on October 21st, 2024.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            Limitations of Use
          </h2>
          <p className="leading-relaxed mb-2">
            By using this website, you warrant on behalf of yourself, your
            users, and other parties you represent that you will not:
          </p>
          <ul className="leading-relaxed mb-2">
            <li>
              modify, copy, prepare derivative works of, decompile, or reverse
              engineer any materials and software contained on this website;
            </li>
            <li>
              remove any copyright or other proprietary notations from any
              materials and software on this website;
            </li>
            <li>
              transfer the materials to another person or "mirror" the materials
              on any other server;
            </li>
            <li>
              knowingly or negligently use this website or any of its associated
              services in a way that abuses or disrupts our networks or any
              other service PGC Software-Proz Ltd provides;
            </li>
            <li>
              use this website or its associated services to transmit or publish
              any harassing, indecent, obscene, fraudulent, or unlawful
              material;
            </li>
            <li>
              use this website or its associated services in violation of any
              applicable laws or regulations;
            </li>
            <li>
              use this website in conjunction with sending unauthorised
              advertising or spam;
            </li>
            <li>
              harvest, collect, or gather user data without the user’s consent;
            </li>
            <li>
              use this website or its associated services in such a way that may
              infringe the privacy, intellectual property rights, or other
              rights of third parties.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            Intellectual Property
          </h2>
          <p className="leading-relaxed mb-2">
            The intellectual property in the materials contained in this website
            are owned by or licensed to PGC Software-Proz Ltd and are protected
            by applicable copyright and trademark law. We grant our users
            permission to download one copy of the materials for personal,
            non-commercial transitory use.
          </p>

          <p className="leading-relaxed mb-2">
            This constitutes the grant of a licence, not a transfer of title.
            This licence shall automatically terminate if you violate any of
            these restrictions or the Terms of Service, and may be terminated by
            PGC Software-Proz Ltd at any time.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            User-Generated Content
          </h2>
          <p className="leading-relaxed mb-2">
            You retain your intellectual property ownership rights over content
            you submit to us for publication on our website. We will never claim
            ownership of your content, but we do require a licence from you in
            order to use it.
          </p>

          <p className="leading-relaxed mb-2">
            When you use our website or its associated services to post, upload,
            share, or otherwise transmit content covered by intellectual
            property rights, you grant to us a non-exclusive, royalty-free,
            transferable, sub-licensable, worldwide licence to use, distribute,
            modify, run, copy, publicly display, translate, or otherwise create
            derivative works of your content in a manner that is consistent with
            your privacy preferences and our Privacy Policy.
          </p>

          <p className="leading-relaxed mb-2">
            The licence you grant us can be terminated at any time by deleting
            your content or account. However, to the extent that we (or our
            partners) have used your content in connection with commercial or
            sponsored content, the licence will continue until the relevant
            commercial or post has been discontinued by us.
          </p>

          <p className="leading-relaxed mb-2">
            You give us permission to use your username and other identifying
            information associated with your account in a manner that is
            consistent with your privacy preferences and our Privacy Policy.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Liability</h2>
          <p className="leading-relaxed mb-2">
            Our website and the materials on our website are provided on an 'as
            is' basis. To the extent permitted by law, PGC Software-Proz Ltd
            makes no warranties, expressed or implied, and hereby disclaims and
            negates all other warranties including, without limitation, implied
            warranties or conditions of merchantability, fitness for a
            particular purpose, or non-infringement of intellectual property, or
            other violation of rights.
          </p>

          <p className="leading-relaxed mb-2">
            In no event shall PGC Software-Proz Ltd or its suppliers be liable
            for any consequential loss suffered or incurred by you or any third
            party arising from the use or inability to use this website or the
            materials on this website, even if PGC Software-Proz Ltd or an
            authorised representative has been notified, orally or in writing,
            of the possibility of such damage.
          </p>

          <p className="leading-relaxed mb-2">
            In the context of this agreement, "consequential loss" includes any
            consequential loss, indirect loss, real or anticipated loss of
            profit, loss of benefit, loss of revenue, loss of business, loss of
            goodwill, loss of opportunity, loss of savings, loss of reputation,
            loss of use and/or loss or corruption of data, whether under
            statute, contract, equity, tort (including negligence), indemnity or
            otherwise.
          </p>

          <p className="leading-relaxed mb-2">
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            Accuracy of Materials
          </h2>
          <p className="leading-relaxed mb-2">
            The materials appearing on our website are not comprehensive and are
            for general information purposes only. PGC Software-Proz Ltd does
            not warrant or make any representations concerning the accuracy,
            likely results, or reliability of the use of the materials on this
            website, or otherwise relating to such materials or on any resources
            linked to this website.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Links</h2>
          <p className="leading-relaxed mb-2">
            PGC Software-Proz Ltd has not reviewed all of the sites linked to
            its website and is not responsible for the contents of any such
            linked site. The inclusion of any link does not imply endorsement,
            approval or control by PGC Software-Proz Ltd of the site. Use of any
            such linked site is at your own risk and we strongly advise you make
            your own investigations with respect to the suitability of those
            sites.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            Right to Terminate
          </h2>
          <p className="leading-relaxed mb-2">
            We may suspend or terminate your right to use our website and
            terminate these Terms of Service immediately upon written notice to
            you for any breach of these Terms of Service.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Severance</h2>
          <p className="leading-relaxed mb-2">
            Any term of these Terms of Service which is wholly or partially void
            or unenforceable is severed to the extent that it is void or
            unenforceable. The validity of the remainder of these Terms of
            Service is not affected.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Governing Law</h2>
          <p className="leading-relaxed mb-2">
            These Terms of Service are governed by and construed in accordance
            with the laws of Republic of Cyprus. You irrevocably submit to the
            exclusive jurisdiction of the courts in that State or location.
          </p>
        </div>
      </AdaptiveModal>
      <AdaptiveModal
        isOpen={GDPRModal}
        onClose={() => setGDPRModal(false)}
        title={<p>GDPR Compliance</p>}
        position={CustomModalPosition.Center}
      >
        <div className="terms-of-service">
          <h2 className="text-2xl font-semibold mt-6 mb-4">
            GDPR Compliance Statement for PGC-SOFTWARE PROZ
          </h2>
          <p className="leading-relaxed mb-2">
            At PGC-SOFTWARE PROZ, we prioritize the privacy and security of our
            users' personal data. As a company based in Cyprus, we are fully
            committed to complying with the EU General Data Protection
            Regulation (GDPR) to safeguard the rights and freedoms of our
            clients and partners.
          </p>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">Data Collection and Processing</span>
            <li>
              We collect only the data necessary to provide our services
              effectively, including user contact details, payment information,
              and data related to service usage.
            </li>
            <li>
              Personal data is processed lawfully, fairly, and transparently,
              ensuring that users are informed about how their data is used.
            </li>
            <li>
              Data is collected strictly for specific, legitimate purposes and
              will not be processed in any way incompatible with these purposes.
            </li>
          </ul>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">Lawful Basis for Processing</span>
            <li>
              Processing personal data is conducted based on one or more of the
              lawful bases established by GDPR, such as user consent,
              performance of a contract, legal obligations, and legitimate
              interests.
            </li>
          </ul>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">Data Storage and Retention</span>
            <li>
              Personal data is stored securely, and access is restricted to
              authorized personnel only.
            </li>
            <li>
              Data is retained only for as long as is necessary to fulfill the
              purposes for which it was collected or as required by law.
            </li>
          </ul>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">User Rights</span>
            <li>
              <span className="font-bold">Right to Access</span>: Users can request information on
              what personal data we hold about them and how it is processed. 
            </li>
            <li>
              <span className="font-bold">Right to Rectification</span>: Users may request corrections
              of any inaccurate or incomplete personal data.
            </li>
            <li>
              <span className="font-bold">Right to Erasure</span>: Users have the right to request
              deletion of their personal data under certain conditions.
            </li>
            <li>
              <span className="font-bold">Right to Restrict Processing</span>: Users may request the
              restriction of their data processing in certain circumstances.
            </li>
            <li>
              <span className="font-bold">Right to Data Portability</span>: Users can request their
              personal data in a structured, commonly used, and machine-readable
              format.
            </li>
            <li>
              <span className="font-bold">Right to Object</span>: Users may object to the processing
              of their data under specific conditions.
            </li>
          </ul>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">Data Security</span>
            <li>
              We implement robust security measures, including encryption,
              access controls, and regular security audits, to protect personal
              data against unauthorized access, alteration, or destruction.
            </li>
          </ul>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">Data Breach Notification</span>
            <li>
              In the event of a data breach that poses a risk to users' rights
              and freedoms, we will promptly notify affected users and relevant
              authorities in accordance with GDPR requirements.
            </li>
          </ul>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">Data Transfer Outside the EU</span>
            <li>
              If it becomes necessary to transfer personal data outside the EU,
              we ensure that appropriate safeguards are in place to protect user
              data, such as Standard Contractual Clauses or an adequacy decision
              by the European Commission.
            </li>
          </ul>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">Third-Party Processors</span>
            <li>
              When partnering with third-party service providers, we only engage
              processors who can demonstrate GDPR compliance, ensuring that user
              data remains protected and processed in line with applicable
              regulations.
            </li>
          </ul>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">Updates to GDPR Compliance</span>
            <li>
              We may update our GDPR compliance practices as regulations evolve.
              Users will be notified of any significant changes that affect
              their personal data rights or our data processing practices.
            </li>
          </ul>
          <ul className="list-disc mb-6 pl-6">
            <span className="font-bold mb-2">Contact Information</span>
            <li>
              For questions or concerns regarding GDPR compliance or to exercise
              any data rights, please contact our Data Protection Officer (DPO)
              at: PGC-SOFTWARE PROZ Address: [Isxyron 24, Kato Polemidia, 4151,
              Limassol, Cyprus] Email: [info@pandatax.com]
            </li>
          </ul>
        </div>
      </AdaptiveModal>
    </div>
  );
};

export default RegisterPage;
