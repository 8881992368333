import React, {useEffect} from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import GenericApi from "@/api/genericApi.tsx";
import {RoutesPathList} from "@/config/routes/Routes.tsx";
import {getData, getUser, saveData} from "@/utils/localStorage.tsx";
import {AuthResponse} from "@/types/Auth.tsx";
import {authStorageUser} from "@/config/constants/StorageKeys.tsx";
import {User} from "@/types/User.tsx";
import {ApiResponse} from "@/types/Api.tsx";
import {useSnackBarAlert} from "@/hooks/useSnackbar.tsx";
import {useQueryClient} from "@tanstack/react-query";
import {UserPayQueryKey} from "@/config/constants/QueryKeys.tsx";


const PaymentResponse: React.FC = () => {

    const [searchParams] = useSearchParams()
    const navigate = useNavigate();
    const currentUser = getUser();
    const { showSnackBar } = useSnackBarAlert()
    const queryClient = useQueryClient();

    useEffect(() => {
        const orderId = searchParams.get("orderId");
        GenericApi.get(`/payment/success/${orderId}`).then(async () => {
            const user: ApiResponse<User> = await GenericApi.get<User>(`/users/${currentUser?.id}`)
            const userData = getData<AuthResponse>(authStorageUser);
            if(!userData) return
            saveData(authStorageUser, {
                ...userData,
                user: user?.data
            });
            queryClient.invalidateQueries({queryKey: [UserPayQueryKey]})
        }).catch((error) => {
            console.error(error)
            showSnackBar(
                "Something went wrong",
                "error",
                {
                    horizontal: "right",
                    vertical: "top"
                }

            )
        })
    }, [currentUser?.id, searchParams]);

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <h1 className="text-2xl font-bold">Payment Response</h1>
                <p className="text-gray-500">Payment response page</p>
                <div className="mt-4">
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                            <strong className="font-bold">Success!{" "}</strong>
                            <span className="block sm:inline">Payment successful</span>
                        </div>
                    
                    <button onClick={() => navigate(RoutesPathList.Billing, {replace: true})} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Billing</button>
            </div>
        </div>
        </div>
    )
}

export default PaymentResponse