import React, { Fragment } from "react";
import { getUser } from "@/utils/localStorage.tsx";

type PricingCardProps = {
  id: number;
  title: string;
  price: number;
  companies: number;
  users: number;
  isCurrentPlan?: boolean;
  isLoading: boolean;
  description?: string;
  onStartNow: (planId: number) => void;
};

const PricingCard: React.FC<PricingCardProps> = ({
  id,
  title,
  price,
  description,
  companies,
  users,
  isCurrentPlan = false,
  onStartNow,
  isLoading,
}) => {
  const costs = [
    {
      title: "10,000 Crypto Credits",
      description: "One-time cost",
      price: "€10",
    },
    {
      title: "Additional Users",
      description: "",
      price: "€10/per",
    },
  ];

  const user = getUser();

  return (
    <div
      className={`border max-w-[25rem] rounded-lg p-6 flex-1 text-center flex flex-col justify-between shadow-md ${
        isCurrentPlan
          ? "border-blue-500 bg-blue-50 shadow-lg"
          : "border-gray-300 bg-white"
      }`}
    >
      {" "}
      <div>
        <h3 className="text-2xl font-medium text-gray-800 mb-2">{title}</h3>
        <div className="text-4xl font-bold text-gray-900 mb-1">€{price}</div>
        <p className="text-sm text-gray-500 mb-4">monthly</p>
        <ul className="text-gray-700 space-y-1 mb-6">
          <li className="text-xl">
            No. of Companies: {companies}
          </li>
          <li className="text-xl">No. of Users: {users}</li>
        </ul>

        <div className="flex justify-center items-center">
          {description ? (
            <p className="text-sm w-full text-gray-500 mb-4 break-words">
              {description}
            </p>
          ) : null}
        </div>

        <div className="p-6 mb-5 bg-white rounded-lg">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Additional Costs
          </h2>
          <hr className="border-gray-200 mb-4" />
          <ul className="space-y-4">
            {costs.map((cost, index) => (
              <li
                key={index}
                className="flex justify-between items-center border-b pb-4 last:border-none"
              >
                <div>
                  <h3 className="text-lg font-medium text-gray-800">
                    {cost.title}
                  </h3>
                  <p className="text-sm text-gray-500 text-start">
                    {cost.description}
                  </p>
                </div>
                <span className="text-lg font-semibold text-gray-900">
                  {cost.price}
                </span>
              </li>
            ))}
            {
              title == "Accounting Firm" ? <li className="flex justify-between items-center border-b pb-4 last:border-none">
              <div>
                  <h3 className="text-lg font-medium text-gray-800">
                    Additional Companies
                  </h3>
                  <p className="text-sm text-gray-500 text-start">
                    for 15 additional Companies
                  </p>
                </div>
                <span className="text-lg font-semibold text-gray-900">
                  €30/mo
                </span>
            </li> : <Fragment/>
            }
          </ul>
        </div>
      </div>
      <div>
        {!isLoading ? (
          <Fragment>
            {isCurrentPlan && user?.is_paid ? (
              <div className="text-blue-500 font-semibold">Current Plan</div>
            ) : (
              <button
                disabled={isLoading}
                className="bg-blue-500 text-white font-semibold px-4 py-2 rounded hover:bg-blue-600 transition"
                onClick={() => {
                  onStartNow(id);
                }}
              >
                {!user?.is_paid && isCurrentPlan ? "Renew" : "Start Now"}
              </button>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <div className="animate-pulse bg-blue-500 text-white font-semibold px-4 py-2 rounded">
              Loading
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
