export enum Mode {
    PRODUCTION = 'production',
    DEVELOPMENT = 'development'
}


export const CHART_OF_ACCOUNTS_HEADINGS = [
    {
        code: "100",
        type: "Fixed Assets",
        name: "NON-CURRENT ASSETS",
        subcategory: "Intangible Assets",
    },
    {
        code: "110",
        type: "Fixed Assets",
        name: "PROPERTY, PLANT & EQUIPMENT",
        subcategory: "Properties & Other Tangible",
    },
    {
        code: "120",
        type: "Current Assets",
        name: "INVESTMENTS",
        subcategory: "Cash at bank and in hand",
    },
    {
        code: "140",
        type: "Long Term Liabilities",
        name: "LOANS",
        subcategory: "Loans",
    },
    {
        code: "150",
        type: "Fixed Assets",
        name: "INTANGIBLE ASSETS",
        subcategory: "Intangible Assets",
    },
    {
        code: "200",
        type: "Current Assets",
        name: "CURRENTS ASSETS",
        subcategory: "Cash at bank and in hand",
    },
    {
        code: "210",
        type: "Current Assets",
        name: "INVENTORY",
        subcategory: "Cash at bank and in hand",
    },
    {
        code: "220",
        type: "Current Assets",
        name: "TRADE & OTHER RECEIVABLES",
        subcategory: "Trade receivables",
    },
    {
        code: "224",
        type: "Current Assets",
        name: "DEBTORS CONTROL ACCOUNT",
        subcategory: "Trade receivables",
    },
    {
        code: "230",
        type: "Long Term Liabilities",
        name: "LOANS",
        subcategory: "Accrued expenses",
    },
    {
        code: "270",
        type: "Current Assets",
        name: "CASH & CASH EQUIVALENT",
        subcategory: "Cash at bank and in hand",
    },
    {
        code: "300",
        type: "Long Term Liabilities",
        name: "NON-CURRENT LIABILITIES",
        subcategory: "Loans",
    },
    {
        code: "310",
        type: "Current Liabilities",
        name: "BORROWINGS",
        subcategory: "Short term borrowing",
    },
    {
        code: "400",
        type: "Current Liabilities",
        name: "CURRENT LIABILITIES",
        subcategory: "Other Creditors/Suppliers",
    },
    {
        code: "411",
        type: "Current Liabilities",
        name: "OTHER PAYABLES",
        subcategory: "Accrued expenses",
    },
    {
        code: "412",
        type: "Current Liabilities",
        name: "CREDITORS CONTROL ACCOUNTS",
        subcategory: "Other Creditors/Suppliers",
    },
    {
        code: "420",
        type: "Current Liabilities",
        name: "BORROWINGS",
        subcategory: "Short term borrowing",
    },
    {
        code: "440",
        type: "Current Liabilities",
        name: "TAX PAYABLE",
        subcategory: "Accrued expenses",
    },
    {
        code: "500",
        type: "Equity",
        name: "EQUITY",
        subcategory: "Equity",
    },
    {
        code: "590",
        type: "Current Liabilities",
        name: "SUSPENSE ACCOUNTS",
        subcategory: "Accrued expenses",
    },
    {
        code: "600",
        type: "Current Assets",
        name: "INCOME",
        subcategory: "Trade receivables",
    },
    {
        code: "604",
        type: "Current Assets",
        name: "INTEREST INCOME",
        subcategory: "Trade receivables",
    },
    {
        code: "608",
        type: "Current Assets",
        name: "DIVIDEND INCOME",
        subcategory: "Trade receivables",
    },
    {
        code: "610",
        type: "Current Assets",
        name: "OTHER INCOME",
        subcategory: "Trade receivables",
    },
    {
        code: "700",
        type: "Current Liabilities",
        name: "EXPENSES",
        subcategory: "Accrued expenses",
    },
    {
        code: "701",
        type: "Current Liabilities",
        name: "ADMINISTRATIVE EXPENSES",
        subcategory: "Accrued expenses",
    },
    {
        code: "720",
        type: "Current Liabilities",
        name: "PROFESSIONAL SERVICES",
        subcategory: "Accrued expenses",
    },
    {
        code: "750",
        type: "Current Liabilities",
        name: "OPERATING EXPENSES",
        subcategory: "Properties & Other Tangible",
    },
    {
        code: "790",
        type: "Current Liabilities",
        name: "FINANCE EXPENSES",
        subcategory: "Properties & Other Tangible",
    },
    {
        code: "800",
        type: "Current Liabilities",
        name: "TAXES & LICENSES",
        subcategory: "Accrued expenses",
    },
    {
        code: "900",
        type: "Current Liabilities",
        name: "PROFIT & LOSS SUSPENCE ACCOUNTS",
        subcategory: "Accrued expenses",
    },
];