import React, { Fragment, useState } from "react";
import bannerMac from "../assets/laptop-image.png";
import office1 from "../assets/pexels-fauxels-3183183.jpg";
import office2 from "../assets/pexels-fauxels-3184360.jpg";
import { PricingCard } from "@/components";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@/types/Api.tsx";
import { PricingPlan } from "@/types/Payment.tsx";
import GenericApi from "@/api/genericApi.tsx";
import { ApiRoutes } from "@/config/routes/ApiRoutes.tsx";
import { PricingPlansQueryKey } from "@/config/constants/QueryKeys.tsx";
import { RoutesPathList } from "@/config/routes/Routes.tsx";
import FAQCard from "@/components/FAQCard";

const Landing: React.FC = () => {
  const { data: pricingPlans } = useQuery<ApiResponse<PricingPlan[]>>({
    queryFn: async () => GenericApi.get<PricingPlan[]>(ApiRoutes.PRICING),
    queryKey: [PricingPlansQueryKey],
  });

  const [openId, setOpenId] = useState<number | null>(null);

  const handleToggle = (id: number) => {
    setOpenId(openId === id ? null : id);
  };

  return (
    <Fragment>
      <section className="bg-secondary-700">
        <div className="flex w-[85%] px-4 py-20 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 xl:py-24 max-[899px]:flex-col-reverse max-[899px]:items-center">
          <div className="mr-auto place-self-center lg:col-span-7 max-[899px]:text-center">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-5xl text-blue-700">
              The 3-in-one Accounting Solution your Business Needs With{" "}
              <span className="underline">ZERO</span> upfront cost (and no
              hidden fees)
            </h1>
            <p className="max-w-2xl mb-6 font-light primarylg:mb-8 md:text-lg lg:text-2xl text-textcolor-700">
              From accounting and payroll, to invoicing - PandaTax is designed
              with you in mind. To streamline all your financials so you can
              focus on your business.
            </p>
            <div className="flex flex-row items-center space-x-5 max-[899px]:justify-center">
              <a
                href="https://calendly.com/pandatax-sales/pandatax-demo"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                Book your Demo here
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>

          <img className="w-[60%] h-[60%]" src={bannerMac} alt="mockup" />
        </div>
      </section>

      <section className="bg-blue-700 mt-6">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-10 lg:px-6">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
            The <span className="underline">Complete</span> Accounting Software
          </h2>
          <p className="sm:text-xl text-white">
            We make sure we have you covered in all areas.
          </p>
          <div className="mt-8 lg:mt-12 space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div>
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 20"
              >
                <path d="M16 14V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 0 0 0-2h-1v-2a2 2 0 0 0 2-2ZM4 2h2v12H4V2Zm8 16H3a1 1 0 0 1 0-2h9v2Z" />
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">Accounting</h3>
              <p className="mb-4 text-white">
                From automated journal entries, government approved VAT reports
                to MiCa approved Crypto Reports; we have everything you need to
                run your accounting department
              </p>
              <a
                href="/accounting"
                className="inline-flex items-center text-sm font-medium text-white hover:text-textcolor-700"
              >
                Learn more about Accounting Features
                <svg
                  className="ml-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <div>
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z" />
                <path d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z" />
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">Payroll</h3>
              <p className="mb-4 text-white">
                Government Approved and ready to print Payroll- just fill out
                all your employees details, click to export, and you’re ready to
                go.
              </p>
              <a
                href="/payroll"
                className="inline-flex items-center text-sm font-medium text-white hover:text-textcolor-700"
              >
                Learn more about Payroll Features
                <svg
                  className="ml-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <div>
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="m0 8.535 7.463 3.762-7.45 3.75c-.026-.17-.013-7.57-.013-7.57Zm10.863 2.837 7.137-3.618v7.237L9 20v-7.627l1.863-.001ZM8.59 11.557 1.002 15.36 8.59 19.2v-7.643Zm8.137-9.43A1.843 1.843 0 1 1 14.884 0a1.843 1.843 0 0 1 1.843 1.843ZM1.078 1.747l.02 6.513L9 10.66V4.258L1.078 1.747ZM10.274 4.3V10.7l6.648-3.38V.92L10.274 4.3Z" />
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">Invoicing</h3>
              <p className="mb-4 text-white">
                Integrated directly into your accounting, you can send or
                receive an invoice, update your journal entries and monitor all
                your transactions in just a few clicks
              </p>
              <a
                href="/invoices"
                className="inline-flex items-center text-sm font-medium text-white hover:text-textcolor-700"
              >
                Learn more about Invoicing Features
                <svg
                  className="ml-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="py-8 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-20 sm:py-16 lg:px-6">
          <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-blue-700">
                Your Accounting requires Modern Solutions
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                The financial world is constantly evolving. Managing your
                accounting, payroll and invoicing needs to be accessible, quick,
                and user friendly.
              </p>
              <ul
                role="list"
                className="pt-8 my-7 space-y-5 border-t border-gray-200 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Web-faced Interface accessible anywhere in the world (with
                    all your data)
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Quick and snappy with zero delays and freezes
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Cloud based with Airtight Security and 99.9% Uptime
                  </span>
                </li>
              </ul>
            </div>
            <img
              className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
              src={office1}
              alt="office feature image"
            />
          </div>
          <div className="gap-8 items-center sm:pt-10 lg:grid lg:grid-cols-2 xl:gap-16 pt-16">
            <img
              className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
              src={office2}
              alt="office feature image 2"
            />
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-blue-700">
                We invest in the future of Accounting.
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                It’s not enough to automate your entries or digitalise your
                reports. Your business needs to adapt to the infinitely changing
                market. That’s why we make sure we’re ahead of all the trends.
              </p>
              <ul
                role="list"
                className="pt-8 my-7 space-y-5 border-t border-gray-200 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    MiCa Approved Crypto Reports
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    24/7 and Automatic Central Bank and Crypto Exchange Rates
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    CY Government Approved Payroll and Accounting Reports
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16 pt-16">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-blue-700">
                Everything your business needs, with zero hidden fees.
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Our goal is to make PandaTax the end solution for your business.
                We cover all your Accounting, Payroll and Invoicing needs and
                one price with zero hidden fees. We strive to provide the best
                software for our clients, and will continually push new features
                and updates.
              </p>
              <ul
                role="list"
                className="pt-8 my-7 space-y-5 border-t border-gray-200 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Pay-as-you-grow - access to all PandaTax features and pay
                    for usage
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Accounting, Payroll and Invoicing accessible from your
                    dashboard
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-blue-600 dark:text-primary-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Constant Feature Updates and Bug Fixes
                  </span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">
                PandaTax isn’t done - we’re just getting started; and we’re in
                it for the long haul.
              </p>
              <p
                onClick={() => {
                  window.location.replace(
                    "https://calendly.com/pandatax-sales/pandatax-demo"
                  );
                }}
                className="mb-8 font-light lg:text-xl"
              >
                Book your demo, and let’s transform your business.
              </p>
            </div>
            <img
              className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/features/feature-office-1.png"
              alt="office feature image"
            />
          </div>
        </div>
        <div className="flex flex-row items-center justify-center space-x-5 mb-16">
          <a
            href="https://calendly.com/pandatax-sales/pandatax-demo"
            className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            Book your Demo here
            <svg
              className="w-5 h-5 ml-2 -mr-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </section>

      <section className="w-[65%] mx-auto mb-12 flex flex-col items-center max-[899px]:w-[95%] max-[899px]:text-center">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-blue-700">
          Our Transparent Pricing at a Glance
        </h2>
        <div className="flex justify-around gap-16 max-w-[80%] w-full max-[899px]:max-w-[95%] max-[899px]:flex-col">
          {/*<div className="w-[20rem]">*/}
          {/*<PricingCardSimple*/}
          {/*  title="Solo Proprietor"*/}
          {/*  description="For the Business Owner and his Accountant"*/}
          {/*  price={87}*/}
          {/*  companies={1}*/}
          {/*  users={2}*/}
          {/*  isCurrentPlan={false}*/}
          {/*  onStartNow={() => {*/}
          {/*    window.location.href='/register'*/}
          {/*  }}*/}
          {/*/>*/}
          {/*</div>*/}
          {/*<div className="w-[20rem]">*/}
          {/*<PricingCardSimple*/}
          {/*  title="Accountant"*/}
          {/*  description="For the Accounting firm that needs a reliable and scalable solution for their business."*/}
          {/*  price={119}*/}
          {/*  companies={15}*/}
          {/*  users={5}*/}
          {/*  isCurrentPlan={false}*/}
          {/*  onStartNow={() => {*/}
          {/*    window.location.href='/register'*/}
          {/*  }}*/}
          {/*/>*/}
          {/*</div>*/}
          {pricingPlans?.data?.map((plan) => (
            <PricingCard
              id={plan.id}
              key={plan.id}
              title={plan.name}
              price={plan.price}
              companies={plan.companies}
              users={plan.users}
              isCurrentPlan={false}
              description={plan.description}
              onStartNow={() => {
                window.location.href = RoutesPathList.Register;
              }}
              isLoading={false}
            />
          ))}
        </div>
        <div className="mt-24 text-4xl tracking-tight font-extrabold text-blue-700">
          Refund/Cancellation Policy
        </div>
        <div className="mb-8 font-light lg:text-xl px-24 text-center mt-4 max-[899px]:px-4">
          If you're not satified with our platform for any reason you can cancel
          and receive a full refund within 7 days of your purchase. Please note
          that Crypto Credits are non-refundable.
        </div>
      </section>

      <section className="w-[65%] mx-auto max-[899px]:w-[95%]">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-blue-700">
          FAQ’s
        </h2>
        <FAQCard
          id={1}
          title="What is a Web-Faced solution?"
          content="Web-faced simply means that it is hosted on the cloud, and is
            accessible via the internet. This allows you to have lightning fast
            speeds when processing your daily tasks, as well as top of the line
            security for all your private documents and statements."
          isOpen={openId === 1}
          onToggle={handleToggle}
        />
        <FAQCard
          id={2}
          title="Why is Web-Faced better than the traditional download to use
            software?"
          content="Simply put, it is less expensive to set up for you by thousands. We
            skip the complicated process of downloading a software on your
            primary computer, allowing you to begin using the power of PandaTax
            within 5 minutes of setting up your account."
          isOpen={openId === 2}
          onToggle={handleToggle}
        />
        <FAQCard
          id={3}
          title=" Does PandaTax have any setup fees?"
          content="None at all. All you have to do is create your account, pick the
            plan that works for you and you’re off to the races."
          isOpen={openId === 3}
          onToggle={handleToggle}
        />
        <FAQCard
          id={4}
          title="Does PandaTax have any hidden fees?"
          content="None at all. What you see is what you get. We aim to be as
            transparent as possible because we believe all relationships need
            honesty and integrity to flourish. Our ‘Pay-as-you-grow’ model is
            adjusted directly to your business and only increases when you pass
            a threshold. Plus, our Crypto Reports/Exchanges are transparently
            explained within the billing section of your account."
          isOpen={openId === 4}
          onToggle={handleToggle}
        />
        <FAQCard
          id={5}
          title="What do you mean ‘All-in-one?’"
          content="We aim to provide all the features any business would need to run
            their Accounting, Payroll, and invoicing. We constantly push updates
            and add new features to make PandaTax as robust as possible."
          isOpen={openId === 5}
          onToggle={handleToggle}
        />
      </section>

      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-10 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-blue-700">
              Our goal is to help you <span id="element"></span>
            </h2>
            <p className="mb-6 font-light text-gray-700 max-w-4xl mx-auto">
              With 2024 underway, it’s time you upgraded your business to the
              next level. And we would be honoured to be a part of your journey.
            </p>
            <a
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-blue-700 border border-blue-700 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Book your demo
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Landing;
