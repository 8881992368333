import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { RoutesPathList } from '@/config/routes/Routes';
import logo from '@/assets/logo.png';
import { getUser } from '@/utils/localStorage';

const Header: React.FC = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownLinkActive, setIsDropdownLinkActive] = useState(false);
  const user = getUser();

  const renderUserInfo = () => {
    if (user) {
      return (
        <Link to={RoutesPathList.CompanyDasboard} className="hidden lg:block text-gray-800 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2">
          Dashboard
        </Link>
      );
    }else{
      return (
        <Link to={RoutesPathList.Login} className="hidden lg:block text-gray-800 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2">Log in</Link>
      );
    }
  }

  return (
    <header>
      <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 z-50">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="/" className="flex items-center">
            <img src={logo} width="120px" alt="panda tax logo" />
          </a>
          <div className="flex items-center lg:order-2">
            {renderUserInfo()}
            <div className="flex flex-row items-center space-x-5">
              <NavLink onClick={() => setIsDropdownLinkActive(false)} to={RoutesPathList.Register} className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                Get started
              </NavLink>
            </div>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
              aria-controls="mobile-menu-2"
              aria-expanded={isMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
              </svg>
              <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </button>
          </div>
          <div className={`justify-between items-center w-full lg:flex lg:w-auto lg:order-1 ${isMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu-2">
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              <li>
                <NavLink
                  to={RoutesPathList.Landing}
                  onClick={() => setIsDropdownLinkActive(false)}
                  className={({ isActive }) =>
                    isActive
                      ? 'block py-2 pr-4 pl-3 text-secondary-700 rounded bg-primary-700 lg:bg-transparent lg:text-blue-700 lg:p-0'
                      : 'block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0'
                  }
                  aria-current="page"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={RoutesPathList.About}
                  onClick={() => setIsDropdownLinkActive(false)}
                  className={({ isActive }) =>
                    isActive
                    ? 'block py-2 pr-4 pl-3 text-secondary-700 rounded bg-primary-700 lg:bg-transparent lg:text-blue-700 lg:p-0'
                    : 'block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0'
                  }
                >
                  About Us
                </NavLink>
              </li>
              <li className='relative'>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className={`${isDropdownLinkActive ? 'text-primary-600' : ''} flex items-center py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 `}
                  type="button"
                >
                  Products
                  <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"></path>
                  </svg>
                </button>
                {isDropdownOpen && (
                  <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700" style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(0, 30px)' }}>
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                      <li>
                        <NavLink
                          to={RoutesPathList.Accounting}
                          onClick={() => {
                            setIsDropdownOpen(false);
                            setIsDropdownLinkActive(true);
                          }}
                          className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-700"
                        >
                          Accounting
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={RoutesPathList.Payroll}
                          onClick={() => {
                            setIsDropdownOpen(false);
                            setIsDropdownLinkActive(true);
                          }}
                          className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-700"
                        >
                          Payroll
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={RoutesPathList.Invoicing}
                          onClick={() => {
                            setIsDropdownOpen(false);
                            setIsDropdownLinkActive(true);
                          }}
                          className={`block px-4 py-2 hover:bg-gray-100 hover:text-blue-700`}
                        >
                          Invoicing
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li>
                <NavLink
                  to={RoutesPathList.Contact}
                  onClick={() => setIsDropdownLinkActive(false)}
                  className={({ isActive }) =>
                    isActive
                    ? 'block py-2 pr-4 pl-3 text-secondary-700 rounded bg-primary-700 lg:bg-transparent lg:text-blue-700 lg:p-0'
                    : 'block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0'
                  }
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
