import React, {Fragment, useState} from "react";
import LoadingPage from "../LoadingPage";
import FallbackPage from "../Fallback";
import GenericApi from "@/api/genericApi";
import { DoubleEntryAccountQueryKey } from "@/config/constants/QueryKeys";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { ApiError, ApiResponse } from "@/types/Api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdaptiveModal } from "@/components";
import { CustomModalPosition } from "@/types/Modal";
import CustomLogger from "@/utils/CustomLogger";
import FormField from "@/components/CustomForm/FormField";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ValidationErrors, FormError } from "@/types/ValidationError";
import { AxiosError, isAxiosError } from "axios";
import { useSnackBarAlert } from "@/hooks/useSnackbar";
import { generateDoubleEntryAccountsInputs, subcategoryOptions } from "@/utils/generateInputs";
import {CHART_OF_ACCOUNTS_HEADINGS} from "@/config/constants/Constants.tsx";
import {
  DoubleEntryAccount,
  DoubleEntryAccountRequest,
  DoubleEntryAccountView,
  DoubleEntryCreateSchema,
} from "@/types/DoubleEntry";
import DoubleEntryItem from "@/components/DoubleEntry/DoubleEntryItem";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { getUser } from "@/utils/localStorage";
import DoubleEntryAccountTax from "./DoubleEntryAccountTax";

const DoubleEntry: React.FC = () => {
  
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<DoubleEntryAccountRequest>({
    resolver: zodResolver(DoubleEntryCreateSchema),
  });
  
  const { selectedCompany } = useSelector((state: RootState) => state.company);
  const queryClient = useQueryClient();
  const { showSnackBar } = useSnackBarAlert();
  const [doubleEntryModal, setDoubleEntryModal] = useState(false);
  const user = getUser();
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [accountType, setAccountType] = useState<string>("");
  const [errorAccount, setErrorAccount] = useState<string | null>(null)


  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAccountType(event.target.value);
  };


  const route = `/double-entry-account/company/${selectedCompany?.id}`;
  const permissions = useSelector((state: RootState) => state.permissions.permissions);
  const permissionExists = permissions.find((p) => p.company === selectedCompany?.id)
  let edit_accounting_and_payroll = permissionExists?.edit_accounting_and_payroll

  if(user?.is_organization){
    edit_accounting_and_payroll = true;
  }

  const typeForSubcategory = watch('type')

  const {
    data: double_entry_account,
    isLoading: isLoadingDoubleEntryAccounts,
    error,
  } = useQuery<ApiResponse<DoubleEntryAccountView[]>>({
    queryFn: async () =>
      GenericApi.get<DoubleEntryAccountView[]>(route),
    queryKey: [DoubleEntryAccountQueryKey],
  });



  const doubleEntry = double_entry_account?.data?.[0]
  console.log(doubleEntry, "UNSINGUR CONT")

  const double_entry_account_inputs = generateDoubleEntryAccountsInputs(doubleEntry ? doubleEntry : null);


  const addDoubleEntryAccountMutation = useMutation<
    ApiResponse<DoubleEntryAccount>,
    ApiError<ValidationErrors>,
    DoubleEntryAccountRequest
  >({
    mutationFn: (data) =>
      GenericApi.post<DoubleEntryAccount>(ApiRoutes.DOUBLE_ENTRY, data),
    onSuccess: async ({ data }) => {
      CustomLogger.log(data);
      reset();
      setDoubleEntryModal(false);
      queryClient.invalidateQueries({ queryKey: [DoubleEntryAccountQueryKey] });
      showSnackBar(
        "DoubleEntryAccount created",
        "success",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
    onError: async ({ error }) => {
      CustomLogger.error(error);
      handleAxiosError(error);
      showSnackBar(
        "Error creating DoubleEntryAccount",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
    },
  });

  const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
    if (isAxiosError<FormError>(error)) {
      console.log(error);
      setErrorAccount(error?.response?.data?.detail ?? 'Something went wrong')
      console.log(errorAccount)
      return;
    }

    if (isAxiosError<ValidationErrors>(error)) {
      console.log(error);
      return;
    }

    console.log(error);
  };

  const onSubmit = (data: DoubleEntryAccountRequest) => {

    if(!selectedCompany) {
      showSnackBar(
        "Please select a company",
        "error",
        { vertical: "top", horizontal: "right" },
        3000
      );
      return;
    }

    const payload = {
      ...data,
      company_id: selectedCompany?.id,
    };

    CustomLogger.log(data);
    setErrorAccount(null)
    addDoubleEntryAccountMutation.mutate(payload);
  };

  const sortCallback = (a: {code: string}, b: {code: string})   => {
    const firstDigitA = parseInt(a.code[0]);
    const firstDigitB = parseInt(b.code[0]);
    
    if (firstDigitA !== firstDigitB) {
        return firstDigitA - firstDigitB;
    }

    return a.code.length - b.code.length;
}



  // const renderOrganizationBySearchValue = () => {
  //   if (searchValue) {
  //     const filteredDoubleEntryAccount = double_entry_account?.data?.sort(sortCallback).filter(
  //       (double_entry_account) =>
  //         double_entry_account.name
  //           .toLowerCase()
  //           .includes(searchValue.toLowerCase())
  //     );
  //
  //     return filteredDoubleEntryAccount?.sort(sortCallback).map((double_entry_account) => {
  //       const isHeading = double_entry_account.name === double_entry_account.name.toUpperCase();
  //       return <DoubleEntryItem
  //         isHeading={isHeading}
  //         doubleEntryAccount={double_entry_account}
  //         key={double_entry_account.id}
  //         edit_accounting_and_payroll={edit_accounting_and_payroll ?? false}
  //       />
  //     });
  //   }
  //
  //   return double_entry_account?.data?.sort(sortCallback).map((double_entry_account) => {
  //     const isHeading = double_entry_account.name === double_entry_account.name.toUpperCase();
  //     return <DoubleEntryItem
  //         isHeading={isHeading}
  //         doubleEntryAccount={double_entry_account}
  //         key={double_entry_account.id}
  //         edit_accounting_and_payroll={edit_accounting_and_payroll ?? false}
  //     />
  //   });
  // };

  const renderOrganizationBySearchValue = () => {
    if (!double_entry_account?.data) {
      console.error("No data found in double_entry_account");
      return null;
    }



    console.log("Raw data:", double_entry_account.data);

    const data = [...double_entry_account.data, ...CHART_OF_ACCOUNTS_HEADINGS];

    const groupedAccounts = data.reduce((acc, account) => {
      if (!account.code || account.code.length < 3) {
        console.warn("Skipping account with invalid code:", account);
        return acc;
      }

      const groupCode = account.code.slice(0, 3);
      if (!acc[groupCode]) acc[groupCode] = [];
      acc[groupCode].push(account as DoubleEntryAccount);
      return acc;
    }, {} as Record<string, DoubleEntryAccount[]>);

    console.log("Grouped accounts:", groupedAccounts);

    const sortedGroupCodes = Object.keys(groupedAccounts).sort((a, b) => parseInt(a) - parseInt(b));

    console.log("Sorted group codes:", sortedGroupCodes);

    return sortedGroupCodes.map((groupCode) => {
      const groupAccounts = groupedAccounts[groupCode];

      groupAccounts.sort(sortCallback);

      console.log(`Group ${groupCode} sorted accounts:`, groupAccounts);

      if(searchValue){
        const filteredDoubleEntryAccount = groupAccounts.filter(
          (double_entry_account) =>
            double_entry_account.name
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        );

        return filteredDoubleEntryAccount.map((account) => {
          const isHeading = account.name === account.name.toUpperCase();
          return (
              <DoubleEntryItem
                  isHeading={isHeading}
                  doubleEntryAccount={account}
                  key={account.id}
                  edit_accounting_and_payroll={edit_accounting_and_payroll ?? false}
              />
          );
        });
      }

      return (
            <Fragment>
              {groupAccounts.map((account) => {
                const isHeading = account.name === account.name.toUpperCase();
                return (
                    <DoubleEntryItem
                        isHeading={isHeading && account?.code?.length === 3}
                        doubleEntryAccount={account}
                        key={account.id}
                        edit_accounting_and_payroll={edit_accounting_and_payroll ?? false}
                    />
                );
              })}
            </Fragment>
      );
    });
  };


  const toggleCompanyModal = () => {
    setDoubleEntryModal(!doubleEntryModal);
    setValue("type", "Fixed Assets") 
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const onRetry = () => {
    window.location.reload();
  };

  if (isLoadingDoubleEntryAccounts)
    return (
      <div className="mt-[4rem]">
        <LoadingPage />
      </div>
    );

  const fetchError = error || double_entry_account?.error;

  if (fetchError) {
    console.error(fetchError);
    return <FallbackPage onRetry={onRetry} />;
  }

  if (!double_entry_account?.data) {
    console.error("Data is undefined");
    return <FallbackPage onRetry={onRetry} />;
  }

  const generateSubcategory = (inputType: string, inputValue: string) => {
    if(inputType === 'select'){
        return  subcategoryOptions[inputValue]
    }
}


  return (
    <>
    <div className="mt-20">
      <select name="account" id="account" value={accountType} onChange={handleSelectChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option value="" disabled>Select Account Type</option>
        <option value="account">Account</option>
        <option value="tax">Account Tax</option>
      </select>
    </div>
    {
      accountType === "tax" ? <DoubleEntryAccountTax /> : <div className="bg-gray-50 mt-[2rem] dark:bg-gray-900">
      <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <div className="w-full md:w-full flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-between md:space-x-3">
            <div className="flex justify-center items-center gap-4">
              <form className="flex items-center">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-width="2"
                        d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearchValue(e.target.value)}
                    type="search"
                    id="default-search"
                    className="block w-full ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search"
                    required
                  />
                </div>
              </form>
              <button
                onClick={toggleFilter}
                id="filterDropdownButton"
                data-dropdown-toggle="filterDropdown"
                className="flex relative items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  className="w-4 h-4 mr-2 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                    clip-rule="evenodd"
                  />
                </svg>
                Filter
                <svg
                  className="-mr-1 ml-1.5 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  />
                </svg>
                {openFilter ? (
                  <div className="z-[9999] absolute top-full w-48 p-3 bg-white rounded-lg shadow dark:bg-gray-700">
                    <h6 className="mb-3 text-sm text-start font-medium text-gray-900 dark:text-white">
                      Date
                    </h6>
                    <ul
                      className="space-y-2 text-sm"
                      aria-labelledby="dropdownDefault"
                    >
                      <li className="flex items-center">
                        <input
                          id="apple"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label
                          htmlFor="apple"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                        >
                          Ascending
                        </label>
                      </li>
                      <li className="flex items-center">
                        <input
                          id="fitbit"
                          type="checkbox"
                          value=""
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                        />
                        <label
                          htmlFor="fitbit"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-100"
                        >
                          Descending
                        </label>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </button>
            </div>

            {edit_accounting_and_payroll ? <button
              onClick={toggleCompanyModal}
              data-modal-toggle="createCompanyModal"
              type="button"
              className="flex items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
            >
              <svg
                className="h-3.5 w-3.5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                />
              </svg>
              Add Account
            </button> : null}
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs w-full text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 space-x-4">
                <th scope="col" className="px-4 py-3 text-left">
                  Name
                </th>
                <th scope="col" className="px-4 py-3 text-center">
                  Code
                </th>
                <th scope="col" className="px-4 py-3 text-center">
                  Type
                </th>
                <th scope="col" className="px-4 py-3 text-right">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>{renderOrganizationBySearchValue()}</tbody>
          </table>
        </div>
      </div>

      <AdaptiveModal
        isOpen={doubleEntryModal}
        onClose={() => setDoubleEntryModal(false)}
        position={CustomModalPosition.Center}
        title={<p className="dark:text-white">Add Account</p>}
      >
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>

        {
              addDoubleEntryAccountMutation.isError ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">{
                                    errorAccount
                                }</span>
                            </div> : null
                        }

          {double_entry_account_inputs.map((input) => {
            const options = generateSubcategory(input.type, typeForSubcategory)
            options
            return (
              <FormField<DoubleEntryAccountRequest>
              key={input.name}
              type={input.type}
              placeholder={input.placeholder}
              name={input.name}
              required={input.required}
              valueAsNumber={input.valueAsNumber}
              inputStyle="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              label={input.label}
              options={input.name === "subcategory" ? options : input.options}
              register={register}
              error={errors[input.name as keyof DoubleEntryAccountRequest]}
            />
            )
          })
          }
          <button
            type="submit"
            className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
          >
            {addDoubleEntryAccountMutation.isPending ? (
              <svg
                className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin"
                viewBox="0 0 24 24"
              ></svg>
            ) : (
              "Create Account"
            )}
          </button>
        </form>
      </AdaptiveModal>
    </div>
    }
    </>
  );
};

export default DoubleEntry;
