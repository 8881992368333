import { z, ZodSchema } from "zod";
import { Company } from "../Company";

export type Reconciliation = {
    id: number;
    company: number;
    account: string;
    started_at: string;
    ended_at: string;
    opening_balance: number;
    closing_balance: number;
    transactions?: number[];
    reconciled: boolean;
}

export type ReconciliationCreateRequest = {
    account: string;
    started_at: string;
    ended_at: string;
    opening_balance: number;
    closing_balance: number;
    reconciled?: boolean;
}

export type ReconciliationUpdate = {
    account: string;
    started_at: string;
    ended_at: string;
    opening_balance: number;
    closing_balance: number;
    reconciled?: boolean;
}


export type ReconciliationType = {
    id: number;
    company: Company;
    account: string;
    started_at: string;
    ended_at: string;
    opening_balance: number;
    closing_balance: number;
    transactions: number[]; 
    reconciled: boolean;
};



export const ReconciliationCreateSchema: ZodSchema<ReconciliationCreateRequest> = z.object({
    account: z.string(),
    started_at: z.string().date(),
    ended_at: z.string().date(),
    opening_balance: z.number(),
    closing_balance: z.number(),
    reconciled: z.boolean(),
});

export const ReconciliationUpdateSchema: ZodSchema<ReconciliationUpdate> = z.object({
    account : z.string(),
    started_at: z.string().date(),
    ended_at: z.string().date(),
    opening_balance: z.number(),
    closing_balance: z.number(),
    reconciled: z.boolean(),
});
