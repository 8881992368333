export const enum RoutesPathList {
    Landing = '/',
    About = '/about',
    Contact = '/contact',
    Login = '/login',
    Register = '/register',
    ActivationSuccess = '/success',
    Accounting = '/accounting',
    Invoicing = '/invoicing',
    Payroll = '/payroll',
    OrganizationDashboard = '/organization-dashboard',
    OrganizationUserProfile = '/organization-user-profile',
    OrganizationUserPassword = '/organization-user-password',
    CompanyDasboard = '/company-dashboard',
    ResetPasswordEmail = '/reset-password-email',
    ResetPassword = '/reset-password',
    Billing = '/company-billing',
    Permissions = '/company-permissions',
    CompanyUsers = '/company-users',
    CreateCompanyUser = '/create-company-user',
    DoubleEntry = '/double-entry',
    Items = "/items",
    Accounts = '/accounts',
    Taxes = '/taxes',
    Contacts = "/contacts",
    Departments = '/departments',
    ExchangeRates = '/exchange-rates',
    Categories = '/categories',
    Employees = '/employee',
    Transfers = '/transfers',
    Transactions = '/transactions',
    Reconciliations = '/reconciliations',
    Documents = '/documents',
    Journals = '/journals',
    Reports = '/reports',
    SuccessPay = '/billing/success',
    Pages = '/pages',
    Salary = '/salary',
    Invoice = '/invoice',
    Client = '/client',
    PayrollPage = '/payroll-page',
    PaymentResponse = '/payment-response-success',
    PaymentFailResponse = '/payment-response-fail',
    RouteCheckMatcher = '*',
    NotFound = '/not-found',
}

export const Routes = [
    {
        isProtected: false,
        component: "CreateCompanyUser",
        route: RoutesPathList.CreateCompanyUser
    },
    {
        isProtected: false,
        component: 'Landing',
        route: RoutesPathList.Landing,
    },
    {
        isProtected: false,
        component: 'About',
        route: RoutesPathList.About,
    },
    {
        isProtected: false,
        component: 'Contact',
        route: RoutesPathList.Contact,
    },
    {
        isProtected: false,
        component: 'Login',
        route: RoutesPathList.Login,
    },
    {
        isProtected: false,
        component: 'Register',
        route: RoutesPathList.Register,
    },
    {
        isProtected: false,
        component: 'Accounting',
        route: RoutesPathList.Accounting,
    },
    {
        isProtected: false,
        component: 'Invoicing',
        route: RoutesPathList.Invoicing,
    },
    {
        isProtected: false,
        component: 'Payroll',
        route: RoutesPathList.Payroll,
    },
    {
        isProtected: true,
        component: 'Organizations',
        route: RoutesPathList.OrganizationDashboard,
    },
    {
        isProtected: true,
        component: 'OrganizationUserProfile',
        route: RoutesPathList.OrganizationUserProfile,
    },
    {
        isProtected: true,
        component: 'OrganizationUserPassword',
        route: RoutesPathList.OrganizationUserPassword,
    },
    {
        isProtected: false,
        component: 'ResetPasswordEmail',
        route: RoutesPathList.ResetPasswordEmail,
    },
    {
        isProtected: false,
        component: 'ResetPassword',
        route: RoutesPathList.ResetPassword,
    },
    {
        isProtected: false,
        component: 'ActivationSuccess',
        route: RoutesPathList.ActivationSuccess,
    },
    {
        isProtected: true,
        component: 'CompanyDasboard',
        route: RoutesPathList.CompanyDasboard,
    },
    {
        isProtected: false,
        component: 'NotFound',
        route: RoutesPathList.NotFound,
    }, 
    {
        isProtected: true,
        component: 'Billing',
        route: RoutesPathList.Billing,
    },
    {
        isProtected: true,
        component: 'Permissions',
        route: RoutesPathList.Permissions,
    },
    {
        isProtected: true,
        component: 'Users',
        route: RoutesPathList.CompanyUsers,
    },
    {
        isProtected: true,
        component: 'DoubleEntry',
        route: RoutesPathList.DoubleEntry,
    },
    {
        isProtected: true,
        component: 'Items',
        route: RoutesPathList.Items,
    },
    {   
        isProtected: true,
        component: 'Accounts',
        route: RoutesPathList.Accounts,
    },
    {
        isProtected: true,
        component: 'Taxes',
        route: RoutesPathList.Taxes,
    },
    {
        isProtected: true,
        component: 'Contacts',
        route: RoutesPathList.Contacts,
    },
    {
        isProtected: true,
        component: 'Departments',
        route: RoutesPathList.Departments,
    },
    {
        isProtected: true,
        component: 'ExchangeRates',
        route: RoutesPathList.ExchangeRates,
    },
    {
        isProtected: true,
        component: 'Categories',
        route: RoutesPathList.Categories,
    },
    {   
        isProtected: true,
        component: 'Employees',
        route: RoutesPathList.Employees,
    },
    {
        isProtected: true,
        component: 'Transfers',
        route: RoutesPathList.Transfers,
    },
    {
        isProtected: true,
        component: 'Reconciliations',
        route: RoutesPathList.Reconciliations,
    },
    {
        isProtected: true,
        component: 'Transactions',
        route: RoutesPathList.Transactions,
    },
    {
        isProtected: true,
        component: 'Documents',
        route: RoutesPathList.Documents,
    },
    {
        isProtected: true,
        component: 'Journals',
        route: RoutesPathList.Journals,
    },
    {
        isProtected: true,
        component: 'Pages',
        route: RoutesPathList.Pages,
    },
    {
        isProtected: true,
        component: 'Salary',
        route: RoutesPathList.Salary,
    },
    {
        isProtected: true,
        component: 'Reports',
        route: RoutesPathList.Reports,
    },
    {
        isProtected: true,
        component: 'Pay',
        route: RoutesPathList.SuccessPay,
    },
    {
        isProtected: true,
        component: 'Invoice',
        route: RoutesPathList.Invoice,
    },
    {
       isProtected: true,
       component: 'Client',
       route: RoutesPathList.Client, 
    },
    {
        isProtected: true,
        component: 'PayrollPage',
        route: RoutesPathList.PayrollPage,
    },
    {
        isProtected: true,
        component: 'PaymentResponse',
        route: RoutesPathList.PaymentResponse,
    },
    {
        isProtected: true,
        component: 'PaymentFailResponse',
        route: RoutesPathList.PaymentFailResponse,
    }
]

