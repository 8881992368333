import { z, ZodSchema } from "zod";


export type Transaction = {
    id: number;
    company: number;
    type: string;
    number: string;
    account: string;
    paid_at: string;
    amount: number;
    currency_code: string;
    currency_rate: string;
    document: string;
    contact: string;
    description: string;
    category: string;
    payment_method: string;
    reference?: string | null;
    split_id?: number | null;
    reconciled: boolean;
}

export type TransactionCreateRequest = {
    type: string;
    number: string;
    account: string;
    paid_at: string;
    amount: number;
    currency_code: string;
    currency_rate: string;
    document: string;
    contact: string;
    description: string;
    category: string;
    payment_method: string;
    reference?: string | null;
    split_id?: number | null;
    reconciled: boolean;
}

export type TransactionUpdate = {
    type: string;
    number: string;
    account: string;
    paid_at: string;
    amount: number;
    currency_code: string;
    currency_rate: string;
    document: string;
    contact: string;
    description: string;
    category: string;
    payment_method: string;
    reference?: string | null;
    split_id?: number | null;
    reconciled: boolean;
}

export const TransactionCreateSchema: ZodSchema<TransactionCreateRequest> = z.object({
    type: z.string().min(1).max(50),
    number: z.string().min(1).max(50),
    account: z.string(),
    paid_at: z.string(),
    amount: z.number(),
    currency_code: z.string().min(1).max(10),
    currency_rate: z.string().regex(/^\d+(\.\d{1,6})?$/),
    document: z.string(),
    contact: z.string(),
    description: z.string(),
    category: z.string(),
    payment_method: z.string().min(1).max(50),
    reference: z.string().max(255).nullable().optional(),
    split_id: z.number().nullable().optional(),
    reconciled: z.boolean()
});

export const TransactionUpdateSchema: ZodSchema<TransactionCreateRequest> = z.object({
    type: z.string().min(1).max(50),
    number: z.string().min(1).max(50),
    account: z.string(),
    paid_at: z.string(),
    amount: z.number(),
    currency_code: z.string().min(1).max(10),
    currency_rate: z.string().regex(/^\d+(\.\d{1,6})?$/),
    document: z.string(),
    contact: z.string(),
    description: z.string(),
    category: z.string(),
    payment_method: z.string().min(1).max(50),
    reference: z.string().max(255).nullable().optional(),
    split_id: z.number().nullable().optional(),
    reconciled: z.boolean()
});


export type TransactionType = {
    id: number;
    company: string;
    type: string;
    number: string;
    account: string;
    paid_at: string;
    amount: string;
    currency_code: string;
    currency_rate: string;
    document: string;
    contact: string;
    description: string;
    category: string;
    payment_method: string;
    reference: string;
    split_id: number;
    reconciled: boolean;
}