import React, { Fragment, useState } from 'react'
import GenericApi from '@/api/genericApi'
import { AccountQueryKey } from '@/config/constants/QueryKeys'
import { ApiRoutes } from '@/config/routes/ApiRoutes'
import { ApiResponse, ApiError } from '@/types/Api'
import { FormError, ValidationErrors } from '@/types/ValidationError'
import CustomLogger from '@/utils/CustomLogger'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackBarAlert } from '@/hooks/useSnackbar'
import { AxiosError, isAxiosError } from 'axios'
import { AdaptiveModal, LoadingSpinner } from '..'
import { CustomModalPosition } from '@/types/Modal'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import FormField from '../CustomForm/FormField'
import { generateAccountUpdateInputs } from '@/utils/generateInputs'
import { Account, AccountCreateRequest, AccountUpdate, AccountUpdateSchema } from '@/types/Banking/Accounts'
import { Currency } from '@/types/Currency'

export interface AccountItemProps {
    account: Account
    currencies: Currency[] | null
}


const AccountItem: React.FC<AccountItemProps> = ({ account, currencies }) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<AccountUpdate>({
        resolver: zodResolver(AccountUpdateSchema),
    });

    console.log({
        errors
    })

    const queryClient = useQueryClient()
    const { showSnackBar } = useSnackBarAlert()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)

    const deleteAccountMutation = useMutation<ApiResponse<{ message: string }>, ApiError<ValidationErrors>, { id: number }>(
        {
            mutationFn: (data) => GenericApi.delete<{ message: string }>(`${ApiRoutes.ACCOUNTS}/${data.id}`),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                queryClient.invalidateQueries({ queryKey: [AccountQueryKey] })
                showSnackBar("Bank Details deleted", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error deleting Bank Details", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )

    const handleAxiosError = (error: AxiosError<unknown> | null | Error) => {
        if (isAxiosError<FormError>(error)) {
            console.log(error)
            return
        }

        if (isAxiosError<ValidationErrors>(error)) {
            console.log(error)
            return
        }

        console.log(error)
    }

    const handleDelete = (id: number) => {
        deleteAccountMutation.mutate({ id })
    }

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen)
    }

    const updateAccountMutation = useMutation<ApiResponse<Account>, ApiError<ValidationErrors>, AccountUpdate>(
        {
            mutationFn: (data) => GenericApi.put<Account>(`${ApiRoutes.ACCOUNTS}/${account.id}`, data),
            onSuccess: async ({ data }) => {
                CustomLogger.log(data)
                setEditModalOpen(false)
                queryClient.invalidateQueries({ queryKey: [AccountQueryKey] })
                showSnackBar("Bank Details updated", 'success', { vertical: 'top', horizontal: 'right' }, 3000)
            },
            onError: async ({ error }) => {
                CustomLogger.error(error)
                handleAxiosError(error)
                showSnackBar("Error updating Bank Details", 'error', { vertical: 'top', horizontal: 'right' }, 3000)
            }
        }
    )

    const updateAccountInputs = generateAccountUpdateInputs(account, currencies)

    const onSubmit = (data: AccountUpdate) => {
        updateAccountMutation.mutate(data)
    }

    return (
        <Fragment>
            <tr
                className="border-b dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
                
                <td className="px-4 py-3 text-center">{account.name}</td>
                <td className="px-4 py-3 text-center">{account.currency}</td>
                <td className="px-4 py-3 text-center">{account.iban}</td>
                <td className="px-4 py-3 text-center">{account.swift}</td>
                
                <td className="px-4 py-3 text-right">
                    <button
                        onClick={toggleEditModal}
                        className="font-medium text-green-400 dark:text-green-400 hover:underline"
                    >
                        Edit
                    </button>
                    <span className="mx-2">|</span>
                    <button
                        onClick={toggleDeleteModal}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                    >
                        Delete
                    </button>

                </td>
            </tr>

            <AdaptiveModal
                isOpen={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Delete Bank Details</h2>}
                position={CustomModalPosition.Center}
                footer={
                    <Fragment>
                        <button
                            onClick={() => handleDelete(account.id)}
                            className="bg-red-600 text-white px-3 py-1.5 rounded-lg hover:bg-red-700"
                        >
                            {
                                deleteAccountMutation.isPending ? <LoadingSpinner variant='danger' /> : 'Delete'
                            }
                        </button>
                        <button
                            onClick={toggleDeleteModal}
                            className="bg-gray-200 text-gray-800 px-3 py-1.5 rounded-lg hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            >
                <p className="text-gray-700 dark:text-gray-300">
                    Are you sure you want to delete this account?
                </p>
            </AdaptiveModal>


            <AdaptiveModal
                isOpen={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title={<h2 className="text-lg font-semibold text-gray-900 dark:text-white">Edit Bank Details</h2>}
                position={CustomModalPosition.Center}
            >
                <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    {
                        updateAccountInputs.map(input => (
                            <FormField<AccountUpdate>
                                key={input.name}
                                type={input.type}
                                placeholder={input.placeholder}
                                name={input.name}
                                required={input.required}
                                valueAsNumber={input.valueAsNumber}
                                inputStyle='mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
                                label={input.label}
                                defaultValue={input?.defaultValue ?? ''}
                                options={input.options}
                                register={register}
                                error={errors[input.name as keyof AccountCreateRequest]}
                            />
                        ))
                    }
                    <button type="submit" className="flex w-full items-center justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        {
                            updateAccountMutation.isPending ? <svg className="w-5 h-5 mr-3 border-r-2 border-white rounded-full animate-spin" viewBox="0 0 24 24"></svg> : "Edit Bank Details"
                        }
                    </button>
                </form>
            </AdaptiveModal>
        </Fragment>
    )
}

export default AccountItem
