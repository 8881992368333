import React, { Fragment, useState } from "react";
import bannerMac from "../assets/laptop-image.png";
import GenericApi from "@/api/genericApi";
import { PricingCard } from "@/components";
import FAQCard from "@/components/FAQCard";
import { PricingPlansQueryKey } from "@/config/constants/QueryKeys";
import { ApiRoutes } from "@/config/routes/ApiRoutes";
import { RoutesPathList } from "@/config/routes/Routes";
import { ApiResponse } from "@/types/Api";
import { PricingPlan } from "@/types/Payment";
import { useQuery } from "@tanstack/react-query";

const Accounting: React.FC = () => {
  const { data: pricingPlans } = useQuery<ApiResponse<PricingPlan[]>>({
    queryFn: async () => GenericApi.get<PricingPlan[]>(ApiRoutes.PRICING),
    queryKey: [PricingPlansQueryKey],
  });

  const [openId, setOpenId] = useState<number | null>(null);

  const handleToggle = (id: number) => {
    setOpenId(openId === id ? null : id);
  };

  return (
    <Fragment>
      <section className="bg-secondary-700">
        <div className="flex w-[80%] px-4 py-8 mx-auto gap-8 lg:grid-cols-12 max-[899px]:flex-col-reverse max-[899px]:items-center">
          <div className="mr-auto place-self-center lg:col-span-7 max-[899px]:text-center">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-primary-700">
              All your Accounting needs Accessible with a few clicks.{" "}
              <span className="element"></span>
            </h1>
            <p className="max-w-2xl mb-6 font-light text-textcolor-700 md:text-lg lg:text-xl">
              From automated entries, to all the chart of accounts your business
              needs, and government ready (and approved) VAT reports; we’ve got
              you covered every step of the way.
            </p>
            <div className="flex flex-row items-center space-x-5 max-[899px]:justify-center">
              <a
                href="https://calendly.com/pandatax-sales/pandatax-demo"
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-blue-800 hover:bg-text-color-700 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
              >
                Book Your Demo
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>

          <img className="w-[60%] h-[60%]" src={bannerMac} alt="mockup" />
        </div>
      </section>

      <section className="bg-blue-700">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-10 lg:px-6">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
            Inside you’ll find all the accounting features you will need.
          </h2>
          <div className="mt-8 lg:mt-12 space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div className="text-white">
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z"
                  clipRule="evenodd"
                ></path>
                <path
                  fillRule="evenodd"
                  d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z"
                  clipRule="evenodd"
                ></path>
                <path
                  fillRule="evenodd"
                  d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <h3 className="mb-2 text-xl font-bold">Automatic Transactions</h3>
              <p className="mb-4">
                Whether you have 1 or 1,000 transactions; rest assured, PandaTax
                can handle and get you in an out in record time
              </p>
            </div>
            <div className="text-white">
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
                <path
                  fillRule="evenodd"
                  d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <h3 className="mb-2 text-xl font-bold">Detailed Reports</h3>
              <p className="mb-4">
                Every report your business needs; from VAT, to MiCa approved
                Crypto reports; PandaTax makes sure to cover all aspects of your
                business
              </p>
            </div>
            <div className="text-white">
              <svg
                className="mx-auto mb-4 w-12 h-12 text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <h3 className="mb-2 text-xl font-bold text-white">
                Daily FX/Crypto Updates
              </h3>
              <p className="mb-4">
                Whether you need to convert currency on the fly, or you need to
                access Crypto historical data to log your entries - it’s right
                there at your fingertips to use at any time.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="max-w-screen-md mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Built to grow with your business.
          </h2>
          <p className="text-gray-500 sm:text-xl dark:text-gray-400">
            We want to be there every step of the way. That’s why we are
            constantly updating and improving, so we can provide the best
            possible accounting solution for your business.
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-2 md:gap-12 md:space-y-0">
          <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
              <svg
                className="text-primary-600 w-12 lg:h-12"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M4 5a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M5 14c0-.6.4-1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0c0-.6.4-1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
              Journal Entries
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Get all your entries filled out at record time, without breaking a
              sweat
            </p>
          </div>
          <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
              <svg
                className="w-12 h-12 text-blue-700 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M3 21h18M4 18h16M6 10v8m4-8v8m4-8v8m4-8v8M4 9.5v-1c0-.3.2-.6.5-.8l7-4.5a1 1 0 0 1 1 0l7 4.5c.3.2.5.5.5.8v1c0 .3-.2.5-.5.5h-15a.5.5 0 0 1-.5-.5Z"
                />
              </svg>
            </div>
            <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
              Direct Bank Access with your account
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              We aim to connect with as many banks as possible to make your
              accounting flow even smoother.
            </p>
          </div>
          <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
              <svg
                className="w-12 h-12 text-primary-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 4v15c0 .6.4 1 1 1h15M8 16l2.5-5.5 3 3L17.3 7 20 9.7"
                />
              </svg>
            </div>
            <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
              Detailed Dashboard view
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              See all your reports and business health at a glance with your own
              dashboard (customizable to your preferences)
            </p>
          </div>
          <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full lg:h-12 lg:w-12 dark:bg-blue-900">
              <svg
                className="w-12 h-12 text-blue-700 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 4h3c.6 0 1 .4 1 1v15c0 .6-.4 1-1 1H6a1 1 0 0 1-1-1V5c0-.6.4-1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z"
                />
              </svg>
            </div>
            <h3 className="mb-2 text-xl font-bold text-gray-900 dark:text-white">
              Quick and Snappy Interface
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Move between screens at lightning speeds, and get all your
              reports, journals, and transactions filed within record time (and
              no delays).
            </p>
          </div>
        </div>
      </section>

      <section className="bg-white py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="max-w-4xl text-center mx-auto">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Let us show you the power of PandaTax.
          </h2>
          <p className="mb-6 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Our goal is to help you run your business easier.
          </p>
          <p className="mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            Give us a chance to show you what we can do, and book your demo
            below.
          </p>

          <div className="flex flex-col space-y-4 sm:flex-row justify-center sm:space-y-0 sm:space-x-4">
            <a
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-blue-700 border border-textcolor-700 rounded-lg hover:bg-text-color-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            >
              Book Demo
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </section>
      <section className="w-[65%] mx-auto mb-12 flex flex-col items-center max-[899px]:w-[95%] max-[899px]:text-center">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-blue-700">
          Our Transparent Pricing at a Glance
        </h2>
        <div className="flex justify-around gap-16 max-w-[80%] w-full max-[899px]:max-w-[95%] max-[899px]:flex-col">
          {/*<div className="w-[20rem]">*/}
          {/*<PricingCardSimple*/}
          {/*  title="Solo Proprietor"*/}
          {/*  description="For the Business Owner and his Accountant"*/}
          {/*  price={87}*/}
          {/*  companies={1}*/}
          {/*  users={2}*/}
          {/*  isCurrentPlan={false}*/}
          {/*  onStartNow={() => {*/}
          {/*    window.location.href='/register'*/}
          {/*  }}*/}
          {/*/>*/}
          {/*</div>*/}
          {/*<div className="w-[20rem]">*/}
          {/*<PricingCardSimple*/}
          {/*  title="Accountant"*/}
          {/*  description="For the Accounting firm that needs a reliable and scalable solution for their business."*/}
          {/*  price={119}*/}
          {/*  companies={15}*/}
          {/*  users={5}*/}
          {/*  isCurrentPlan={false}*/}
          {/*  onStartNow={() => {*/}
          {/*    window.location.href='/register'*/}
          {/*  }}*/}
          {/*/>*/}
          {/*</div>*/}
          {
              pricingPlans?.data?.map((plan) => (
                <PricingCard
                    id={plan.id}
                    key={plan.id}
                    title={plan.name}
                    price={plan.price}
                    companies={plan.companies}
                    users={plan.users}
                    isCurrentPlan={false}
                    description={plan.description}
                    onStartNow={() => {
                        window.location.href = RoutesPathList.Register
                    }}
                    isLoading={false}
                />
            ))
          }
        </div>
        <div className="mt-24 text-4xl tracking-tight font-extrabold text-blue-700">
          Refund/Cancellation Policy
        </div>
        <div className="mb-8 font-light lg:text-xl px-24 text-center mt-4 max-[899px]:px-4">
          If you're not satified with our platform for any reason you can cancel
          and receive a full refund within 7 days of your purchase. Please note
          that Crypto Credits are non-refundable.
        </div>
      </section>

      <section className="w-[65%] mx-auto mb-[4rem] max-[899px]:w-[95%]">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-blue-700">
          FAQ’s
        </h2>
        <FAQCard
          id={1}
          title="What is a Web-Faced solution?"
          content="Web-faced simply means that it is hosted on the cloud, and is
            accessible via the internet. This allows you to have lightning fast
            speeds when processing your daily tasks, as well as top of the line
            security for all your private documents and statements."
          isOpen={openId === 1}
          onToggle={handleToggle}
        />
        <FAQCard
          id={2}
          title="Why is Web-Faced better than the traditional download to use
            software?"
          content="Simply put, it is less expensive to set up for you by thousands. We
            skip the complicated process of downloading a software on your
            primary computer, allowing you to begin using the power of PandaTax
            within 5 minutes of setting up your account."
          isOpen={openId === 2}
          onToggle={handleToggle}
        />
        <FAQCard
          id={3}
          title=" Does PandaTax have any setup fees?"
          content="None at all. All you have to do is create your account, pick the
            plan that works for you and you’re off to the races."
          isOpen={openId === 3}
          onToggle={handleToggle}
        />
        <FAQCard
          id={4}
          title="Does PandaTax have any hidden fees?"
          content="None at all. What you see is what you get. We aim to be as
            transparent as possible because we believe all relationships need
            honesty and integrity to flourish. Our ‘Pay-as-you-grow’ model is
            adjusted directly to your business and only increases when you pass
            a threshold. Plus, our Crypto Reports/Exchanges are transparently
            explained within the billing section of your account."
          isOpen={openId === 4}
          onToggle={handleToggle}
        />
        <FAQCard
          id={5}
          title="What do you mean ‘All-in-one?’"
          content="We aim to provide all the features any business would need to run
            their Accounting, Payroll, and invoicing. We constantly push updates
            and add new features to make PandaTax as robust as possible."
          isOpen={openId === 5}
          onToggle={handleToggle}
        />
      </section>
    </Fragment>
  );
};

export default Accounting;
